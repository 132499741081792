import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { ServicesURL, arrayInString } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ButtonBackPage from "@components/ButtonBackPage";
import { ActivitiesTypes, ActivityType } from "src/interfaces/Activities";
import { ActionsType, ColumnsType } from "src/interfaces/Common";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import { useHeader } from "@context/header";
import AnimationFadeIn from "@components/AnimationFadeIn";

const ActivitiesDigited: React.FC = () => {
  const [idToDelete, setIdToDelete] = useState(undefined);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const { setHideHeader } = useHeader();
  const [openDecompressionModal, setOpenDecompressionModal] = useState(false);
  const [decompressionDescription, setDecompressionDescription] = useState("");

  //GET
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
  } = useFetchTable<ActivitiesTypes>(
    ServicesURL.getActivities.replace(":idCorporate", "1"),
    PagesID["catalog.activities"],
  );
  //TRANSLATION
  const { t } = useTranslation(["activities-digited-page", "modal"]);

  //DELETE
  const handleDelete = React.useCallback(async () => {
    setOpenToast({ s: StatusEnum.KO, message: "WIP: DELETE" });
  }, []);

  const columnsMerged =
    columns?.map((el: ColumnsType) => {
      switch (el.accessorKey) {
        case "corporate_visibility": {
          return {
            ...el,
            Cell: ({ cell }: any) => {
              const value = cell.getValue();
              return arrayInString(value, "label");
            },
          };
        }
        default:
          return el;
      }
    }) || [];

  const configurationActionForRow: ActionsType[] = React.useMemo(() => {
    return actionForRow?.map((action: any) => {
      switch (action.key) {
        case "decompression":
          return {
            ...action,
            callBack: (id: any) => {
              getSingleActivities(id);
            },
          };
        default:
          return action;
      }
    });
  }, [actionForRow]);

  const customRenderRowActions = React.useMemo(
    () =>
      ({ row }: { row: any }): React.ReactNode => {
        let configRowNew = configurationActionForRow && [
          ...configurationActionForRow,
        ];

        const excludeDecompression = configRowNew.filter(
          (item: any) => item.type !== "decompression",
        );

        if (
          row.original.type === ActivityType.DA_SCORM ||
          row.original.type === ActivityType.DA_XAPI
        ) {
          const result = row.original.unarchive_result;
          const status = result?.status;

          let decompressionObject = configRowNew.filter(
            (item: ActionsType) => item.key === "decompression",
          )[0];

          if (status === "OK") {
            configRowNew = excludeDecompression;
          }

          if (status === "KO") {
            decompressionObject.icon = "error";
          }

          if (status === null) {
            decompressionObject.icon = "warning";
          }

          let configurationList;

          if (result === null) {
            configurationList = configRowNew.filter(
              (item: any) => item.type !== "decompression",
            );
          } else {
            configurationList = configRowNew;
          }

          return [
            <CustomRowAction
              key={row.id}
              row={row}
              configurationActionForRow={configurationList}
              setHideHeader={setHideHeader}
            />,
          ];
        }

        return [
          <CustomRowAction
            key={row.id}
            row={row}
            configurationActionForRow={excludeDecompression}
            setHideHeader={setHideHeader}
          />,
        ];
      },
    [configurationActionForRow, setHideHeader],
  );

  const getSingleActivities = (id: number) => {
    const activity = dataTable.output.filter((item: any) => item.id === id)[0];
    const decompressionStatus = activity.unarchive_result?.status;
    switch (decompressionStatus) {
      case "KO":
        setDecompressionDescription(activity.unarchive_result?.errorMessage);

        break;

      case null:
        setDecompressionDescription(`${t("modal-decompression.loading")}`);
        break;

      default:
        break;
    }
    setOpenDecompressionModal(true);
  };

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-activities.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id={PagesID["catalog.activities"]}
              data={dataTable?.output}
              isActivities={true}
              columns={columnsMerged || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={actionsTopToolbar}
              renderRowActions={customRenderRowActions}
              totalPages={dataTable?.metadata?.total_elements}
              numberOfActions={configurationActionForRow?.length}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!idToDelete}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        onCallBackClosed={() => {
          setIdToDelete(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        routeToSamePage={true}
        status={openToast}
      />
      <ModalConfirmsCst
        open={!!openDecompressionModal}
        title={t("modal-decompression.title")}
        description={decompressionDescription || ""}
        removeButtons={true}
        onCallBackClosed={() => {
          setOpenDecompressionModal(false);
        }}
        onActionConfirmed={() => {}}
      />
    </AnimationFadeIn>
  );
};

export default ActivitiesDigited;
