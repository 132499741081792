import { Typography, Grid, Divider, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useFetchTable } from "../../hooks/useFetchDataTable";
import { ServicesURL } from "../../utils/utilsApi";
import { PagesID } from "../../utils/utilsConfigurations";
import BadgeRole from "../BadgeRole/Index";

export const DetailUserKnowledge = ({ data, tab }: any) => {
  const { idCampaign, id } = useParams();
  const { id: idUser, user_name, role, user_last_name, user_id } = data;

  const detailCampaignKnowledge = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `KNOWLEDGE`) + `&campaign_user_id=${idUser}`,
    PagesID["corporate.campaign.gap.management"]
  );

  const detailCampaignCapacity = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `CAPACITY`) + `&campaign_user_id=${idUser}`,
    PagesID["corporate.campaign.gap.management.capacity"]
  );

  const knowledge_area: any[] = [];
  const knowledge_domain: any[] = [];
  const capacity: any[] = [];

  detailCampaignKnowledge &&
    detailCampaignKnowledge?.dataTable?.output.map((el: any) => {
      knowledge_area.push(el.knowledge_area.name);
      knowledge_domain.push(el.knowledge_domain.name);
    });

  detailCampaignCapacity &&
    detailCampaignCapacity?.dataTable?.output.map((el: any) => {
      capacity.push(el.capacity.name);
    });

  const { t } = useTranslation("training-needs-details-user");

  const renderPreferences = (title: string, items: any) => (
    <Grid item xs={6}>
      <Typography variant="h6" sx={{ marginBottom: 3 }}>
        {title}
      </Typography>
      {items.map((name: any, index: number) => {
        if (!name) return;
        return (
          <Box key={index}>
            <Typography sx={{ marginBottom: 1.5 }}>{name}</Typography>
            <Divider sx={{ bgcolor: "grey", marginBottom: 1 }} />
          </Box>
        );
      })}
    </Grid>
  );

  return (
    <Box paddingTop={3}>
      <Box sx={{ display: "flex", alignItems: "baseline", gap: 5 }}>
        <BadgeRole role={role} />
        <Typography
          sx={{ color: "#f43f63", fontSize: "0.75rem" }}
          variant="h6"
          gutterBottom
        >
          ID: {user_id}
        </Typography>
      </Box>
      <Box paddingTop={2}>
        <Typography sx={{ marginBottom: 2 }}>
          {user_name} {user_last_name}
        </Typography>
        <Typography paddingTop={2} variant="h3" sx={{ marginBottom: 2 }}>
          {t("preferences")}
        </Typography>
        <Grid paddingTop={3} container spacing={2}>
          {tab === "knowledge" && (
            <>
              {renderPreferences(t("knowledgeArea"), knowledge_area)}
              {renderPreferences(t("knowledgeDomain"), knowledge_domain)}
            </>
          )}
          {tab === "capacity" &&
            capacity &&
            renderPreferences(t("capacity"), capacity)}
        </Grid>
      </Box>
    </Box>
  );
};
