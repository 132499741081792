// Import
import React, { useState } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ButtonCst from "@components/ButtonCst";
import LibraryListBox from "@components/LibraryListBoxCst";
import { PagesID } from "@utils/utilsConfigurations";
import { ServicesURL } from "@utils/utilsApi";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { Media } from "src/interfaces/Media";
import MediaLibraryFormCst from "@components/MediaLibraryFormCst";
import { useAddEditLibrary } from "@hooks/useAddEditLibrary";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";

// Interface
interface AssociationLibraryCstInterface {
  setCloseAssociation?: any;
  association: any;
  setAssociation: any;
  title: string;
  filters?: { field: string; value: any }[];
  multiple?: boolean;
  limit?: number;
}

// eslint-disable-next-line no-empty-pattern
const AssociationLibraryCst: React.FC<AssociationLibraryCstInterface> = ({
  setCloseAssociation,
  association,
  setAssociation,
  filters = [],
  multiple = false,
  title,
  limit,
}) => {
  const inputs = [
    {
      accessorKey: "title",
      header: "headerTitle",
      type: "text",
      permission: "text",
      required: false,
      id: "name",
    },
    {
      accessorKey: "search",
      header: "headerSearch",
      type: "text",
      permission: "search",
      required: false,
      id: "title",
      tooltip: "headerSearchTooltip",
      textTooltip: "headerSearchTextTooltip",
    },
    {
      accessorKey: "name",
      header: "headerName",
      type: "text",
      required: false,
      id: "file-name",
    },
    {
      accessorKey: "date",
      header: "headerDate",
      type: "dateTime",
      required: false,
      id: "lastModifiedDate",
    },
  ];

  const { id } = useParams();
  const [add, setAdd] = useState(false);

  const mediaTypeFilter = filters?.find((item) => item.field === "type")?.value;

  const learningTypeFilter = filters?.find(
    (item) => item.field === "learning_resource_type",
  )?.value;

  const getUrl = () => {
    if (learningTypeFilter) {
      return (
        ServicesURL.library +
        `?corporateId=${
          id || 1
        }&generateUrl=true&learning_resource_type=${learningTypeFilter}`
      );
    } else {
      return (
        ServicesURL.library +
        `?corporateId=${id || 1}&generateUrl=true&type=${mediaTypeFilter}`
      );
    }
  };

  const { dataTable, actionForRow, handleFetchData, columns, mutate } =
    useFetchTable<Media[]>(getUrl(), PagesID["corporate.library"]);

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const handleSetOpenToast = React.useCallback(
    (args: { s: StatusEnum; message: string }) => setOpenToast(args),
    [],
  );

  const { onSubmit } = useAddEditLibrary(
    id || "1",
    undefined,
    handleSetOpenToast,
  );

  const [selected, setSelected] = useState<any>([]);
  const { t } = useTranslation(["form"]);
  const theme = useTheme();

  const objectCssButton = {
    minWidth: "140px",
    [theme.breakpoints.down("lg")]: {
      minWidth: "100px",
    },
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
    },
  };

  if (add) {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          }}
        >
          <Typography variant="h4" component={"h3"}>
            {title}
          </Typography>
          <ButtonCst
            id={"back-button"}
            variant={"outlined"}
            size={"medium"}
            sx={objectCssButton}
            onClick={() => {
              setAdd(false);
            }}
          >
            {t("backButton")}
          </ButtonCst>
        </Box>
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <MediaLibraryFormCst
            formActionType={["upload"]}
            row={{
              metadata: {
                mediaType: mediaTypeFilter,
                learningResourceType: learningTypeFilter,
              },
            }}
            mediaTypeFilter={mediaTypeFilter}
            learningTypeFilter={learningTypeFilter}
            isEdit={false}
            columns={columns || []}
            onSubmit={onSubmit}
            dragDrop={true}
            backButton={() => {
              setAdd(false);
            }}
          />
        </Box>
        <ModalConfirmsCst
          open={!!openToast}
          title={t("hero-title-add")}
          description={""}
          onCallBackClosed={() => {
            setOpenToast(undefined);
          }}
          status={openToast}
          callBackConfirmed={() => {
            mutate();
            setOpenToast(undefined);
            setAdd(false);
          }}
        />
      </>
    );
  } else {
    return (
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            gap: "16px",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          }}
        >
          <ButtonCst
            id={"back-button"}
            variant={"outlined"}
            size={"medium"}
            sx={objectCssButton}
            onClick={() => {
              setCloseAssociation(false);
            }}
          >
            {t("backButton")}
          </ButtonCst>
          <ButtonCst
            id={"save-button"}
            variant={"contained"}
            size={"medium"}
            sx={objectCssButton}
            onClick={() => {
              setAssociation(selected);
              setCloseAssociation(false);
            }}
          >
            {t("saveButton")}
          </ButtonCst>
        </Box>

        <Box
          sx={{
            mt: "25px",
          }}
        >
          <LibraryListBox
            idLibrary={PagesID.library}
            //Add action removed
            // actionPermissions={actionForRow.filter((el) => el.type !== "add")}
            actionPermissions={actionForRow}
            filterList={inputs}
            fileList={dataTable}
            paginationDefault={16}
            isMultiCheck={multiple}
            onChangeFilterForm={handleFetchData}
            onChange={(values) => setSelected(values)}
            checkable
            selected={association}
            association={true}
            associationTitle={title}
            selectionLimit={limit}
            customAddFunction={() => setAdd(true)}
          />
        </Box>
      </>
    );
  }
};

export default AssociationLibraryCst;
