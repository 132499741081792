// Import
import React from "react";
import dayjs from "dayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import "dayjs/locale/it";
import { Box, useTheme } from "@mui/material";
import { useEffect } from "react";
import { DateView } from "@mui/x-date-pickers";
import i18next from "i18next";

// Interface
interface DataPickerCstInterface {
  id: string;
  label?: string;
  value: any;
  defaultValue?: any;
  placeholder?: string;
  onChange: (value: any) => void;
  customWidth?: string;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  required?: boolean;
  sx?: { [key: string]: string };
  isFilterActive?: boolean;
  maxDate?: any;
  minDate?: any;
  views?: readonly DateView[];
}

// eslint-disable-next-line no-empty-pattern
const DataPickerCst: React.FC<DataPickerCstInterface> = (props) => {
  const {
    id,
    label,
    value = null,
    defaultValue = null,
    onChange,
    customWidth,
    error,
    errorMessage,
    disabled,
    required,
    sx,
    isFilterActive,
    maxDate,
    minDate,
    views,
  } = props;

  const theme = useTheme();
  const currentLanguage = i18next.language;

  // useEffect
  useEffect(() => {
    if (!value && defaultValue) {
      onChange(defaultValue);
    }
  }, [defaultValue]);

  return (
    <Box
      id={id}
      sx={{
        minWidth: "200px",
        display: "flex",
        width: customWidth ? customWidth : "auto",
        ...sx,
      }}
    >
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={currentLanguage}
      >
        <DatePicker
          views={views}
          label={label}
          value={value ? dayjs(value) : null}
          defaultValue={defaultValue ? dayjs(defaultValue) : null}
          onChange={(value: any) => {
            onChange(value);
          }}
          maxDate={maxDate ? dayjs(maxDate) : null}
          minDate={minDate ? dayjs(minDate) : null}
          sx={{
            width: "100%",
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: isFilterActive ? theme.palette.text.primary : "",
            },
          }}
          slotProps={{
            textField: {
              size: "small",
              error: error,
              helperText: error ? errorMessage : "",
              required: required,
            },
          }}
          disabled={disabled}
        />
      </LocalizationProvider>
    </Box>
  );
};

export default DataPickerCst;
