import ButtonCst from "@components/ButtonCst";
import NewsCardCst from "@components/NewsCardCst";
import { Add, FilterListOff, FilterListOutlined } from "@mui/icons-material";
import {
  Box,
  Collapse,
  Grid,
  Pagination,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import * as React from "react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LibraryInterface } from "./types";
import { useTranslation } from "react-i18next";
import LibraryFilterBox from "./components/LibraryFilterBox";
import { getLocalStorage } from "@utils/utilsCookie";
import { useAuth } from "@context/auth";
import dayjs from "dayjs";

const LibraryListBox: React.FC<LibraryInterface> = ({
  idLibrary,
  fileList = [],
  paginationDefault = 4,
  isMultiCheck,
  filterList,
  actionPermissions,
  checkable,
  onChange,
  selected,
  association,
  associationTitle,
  onChangeFilterForm,
  handleDelete,
  customAddFunction,
  selectionLimit,
}) => {
  const { user } = useAuth();
  const libraryFilter = getLocalStorage(`${user?.id_user}_${idLibrary}`);
  const theme = useTheme();
  const { t } = useTranslation(["media-library-page"]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: paginationDefault,
  });
  const [activeFilters, setActiveFilters] = useState({});
  const [cardsSelected, setCardsSelected] = useState<any[]>(selected || []);
  const [showFilter, setShowFilter] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!!libraryFilter) {
      setShowFilter(true);
      const filters = JSON.parse(libraryFilter);
      handleChangeFilters(filters);
    }
  }, [libraryFilter]);

  const filteredData = React.useMemo(() => {
    if (fileList?.output?.length === 0) return [];
    const filtered = fileList?.output?.filter((file: any) => {
      return file;
    });
    return filtered;
  }, [fileList]);

  useEffect(() => {
    onChange && onChange(cardsSelected);
  }, [cardsSelected, onChange]);

  const handleChange = useCallback(
    (event: any, value: any) => {
      setPagination({
        ...pagination,
        pageIndex: value - 1,
      });
      onChangeFilterForm &&
        onChangeFilterForm({
          ...activeFilters,
          ...{
            ...pagination,
            pageIndex: value - 1,
          },
        });
    },
    [activeFilters, onChangeFilterForm, pagination],
  );

  const handleChangeFilters = useCallback(
    (filters: any) => {
      const typeFilter = filters?.type?.map((type: any) => {
        return type.value?.toUpperCase();
      });

      let obj: any = {};

      if (filters.title) {
        obj.title = filters.title;
      }
      if (filters.search) {
        obj.search = filters.search;
      }
      if (filters.date) {
        obj.date = dayjs(filters.date).valueOf();
      }
      if (filters.name) {
        obj.name = filters.name;
      }
      if (filters.learning_resource_type) {
        obj.learning_resource_type = filters.learning_resource_type;
      }
      if (typeFilter && typeFilter.length > 0) {
        obj.type = typeFilter;
      }

      if (onChangeFilterForm) {
        setActiveFilters(obj);
        onChangeFilterForm({ ...obj, ...pagination });
      }
    },
    [onChangeFilterForm, pagination],
  );

  const handleSelection = (
    isCardSelected: boolean,
    id: string,
    name: string,
  ) => {
    if (!!isMultiCheck) {
      if (isCardSelected === true) {
        setCardsSelected([...cardsSelected, { id, label: name }]);
      } else {
        const newArray = cardsSelected.filter((item) => {
          if (item.id) {
            return item.id !== id;
          } else {
            return item !== id;
          }
        });
        setCardsSelected(newArray);
      }
    } else {
      if (isCardSelected === true) {
        const array = [{ id, label: name }];
        setCardsSelected(array);
      } else {
        setCardsSelected([]);
      }
    }
  };

  const getPermission = (type: string) => {
    if (actionPermissions && actionPermissions.length > 0) {
      const filter = actionPermissions?.filter(
        (item: any) => item.type === type,
      );
      if (filter.length === 1) {
        if (
          user?.permissions.find(
            (userPermission) => userPermission === filter[0].permission,
          )
        ) {
          return true;
        }
      }
    }
  };

  const handleAdd = () => {
    if (customAddFunction) {
      customAddFunction();
    } else {
      navigate("add");
    }
  };

  return (
    <Box
      sx={{
        background: theme.palette.background.default,
        border: "1px solid",
        borderColor: theme.palette.divider,
        boxShadow: "11px 32px 63px rgba(0, 0, 0, 0.1)",
      }}
    >
      {filterList && (
        <Collapse in={showFilter}>
          <LibraryFilterBox
            id={idLibrary}
            columns={filterList}
            association={association}
            onChangeFilter={handleChangeFilters}
            keyLang={"media-library-page"}
          />
        </Collapse>
      )}

      <Box sx={{ p: "24px" }}>
        <Box
          sx={{
            marginBottom: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h4" component={"h3"}>
            {associationTitle}
          </Typography>
          <Box>
            {getPermission("add") && (
              <ButtonCst
                id="add-file"
                onClick={() => handleAdd()}
                sx={{
                  backgroundColor: "transparent",
                  color: theme.palette.text.primary,
                  padding: 0,
                  minWidth: "40px",
                }}
              >
                <Tooltip title={t("addCta")}>
                  <Add />
                </Tooltip>
              </ButtonCst>
            )}
            {filterList && (
              <ButtonCst
                id="filter"
                onClick={() => setShowFilter(!showFilter)}
                sx={{
                  backgroundColor: "transparent",
                  color: theme.palette.text.primary,
                  padding: 0,
                  minWidth: "40px",
                }}
              >
                <Tooltip title={t("filters")}>
                  {showFilter === true ? (
                    <FilterListOff />
                  ) : (
                    <FilterListOutlined />
                  )}
                </Tooltip>
              </ButtonCst>
            )}
          </Box>
        </Box>
        {fileList?.output?.length > 0 && (
          <Grid container spacing={2}>
            {filteredData.map((card: any, index: any) => {
              return (
                card && (
                  <Grid key={index} item xs={12} sm={6} lg={4} xl={3}>
                    <NewsCardCst
                      date={card?.creationDate}
                      category={card?.metadata?.mediaType}
                      name={card?.metadata?.name}
                      image={card?.url?.public_url || ""}
                      description={card?.metadata?.description}
                      id={card?.id}
                      author={card?.createdBy}
                      onDelete={handleDelete}
                      onSelection={handleSelection}
                      selectedCards={cardsSelected}
                      actionPermissions={actionPermissions}
                      checkable={checkable}
                      selectionLimit={selectionLimit}
                      cardsSelectedCount={cardsSelected.length}
                    />
                  </Grid>
                )
              );
            })}
          </Grid>
        )}
        {fileList?.output?.length === 0 && (
          <Typography variant="h4" component={"p"} sx={{ textAlign: "center" }}>
            {t("no-file-uploaded")}
          </Typography>
        )}
        {fileList?.output?.length > 0 && filteredData.length === 0 && (
          <Typography variant="h4" component={"p"} sx={{ textAlign: "center" }}>
            {t("no-file-uploaded")}
          </Typography>
        )}
        {
          <Pagination
            count={fileList?.metadata?.total_pages}
            shape="rounded"
            page={pagination?.pageIndex + 1}
            onChange={handleChange}
          />
        }
      </Box>
    </Box>
  );
};

export default LibraryListBox;
