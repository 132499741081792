import React, { useState, useEffect } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFetchTable } from "@hooks/useFetchDataTable";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { ServicesURL, arrayInString } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { Path } from "src/interfaces/Path";
import ButtonBackPage from "@components/ButtonBackPage";
import { ColumnsType } from "src/interfaces/Common";
import AnimationFadeIn from "@components/AnimationFadeIn";

const PathsDigited: React.FC = () => {
  const [idToDelete, setIdToDelete] = useState(undefined);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  //GET
  const {
    columns,
    dataTable,
    handleFetchData,
    actionsTopToolbar,
    actionForRow,
  } = useFetchTable<Path[]>(
    ServicesURL.getPaths.replace(":idCorporate", "1"),
    PagesID["catalog.path"],
  );
  //TRANSLATION
  const { t } = useTranslation(["paths-digited-page", "modal"]);
  //DELETE
  const handleDelete = React.useCallback(async () => {
    setOpenToast({ s: StatusEnum.KO, message: "WIP: DELETE" });
  }, []);

  const columnsMerged =
    columns?.map((el: ColumnsType) => {
      switch (el.accessorKey) {
        case "corporate_visibility": {
          return {
            ...el,
            Cell: ({ cell }: any) => {
              const value = cell.getValue();
              return arrayInString(value, "label");
            },
          };
        }
        default:
          return el;
      }
    }) || [];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-paths.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <Table
              id={PagesID["catalog.path"]}
              data={dataTable?.output}
              columns={columnsMerged || []}
              onChangeFilterForm={handleFetchData}
              configurationTopToolbar={actionsTopToolbar}
              configurationActionForRow={actionForRow?.map((action: any) =>
                action.type === "delete"
                  ? { ...action, callBack: (id: any) => setIdToDelete(id) }
                  : action,
              )}
              totalPages={dataTable?.metadata?.total_elements}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!idToDelete}
        title={t("delete-item", { ns: "modal" })}
        routeToSamePage={true}
        description={t("confirm-delete", { ns: "modal" })}
        onCallBackClosed={() => {
          setIdToDelete(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => handleDelete()}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default PathsDigited;
