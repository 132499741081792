import React, { useCallback, useEffect, useState } from "react";
import MaterialReactTable, {
  MRT_BottomToolbar,
  MRT_ColumnDef,
  MRT_PaginationState,
} from "material-react-table";
import { useTheme } from "@mui/material/styles";
import { MRT_Localization_IT } from "material-react-table/locales/it";
import { MRT_Localization_EN } from "material-react-table/locales/en";
import { CustomLi, Row } from "./style";
import { TableInterface } from "./types";
import { useHeader } from "@context/header";
import { getLocalStorage, setLocalStorage } from "@utils/utilsCookie";
import CustomTopToolbar from "./components/CustomTopToolbar";
import CustomRowAction from "./components/CustomRowAction";
import { Box, capitalize, Checkbox, Tooltip } from "@mui/material";
import { useTranslation } from "react-i18next";
import OptionsConfig from "../../assets/static/options.json";
import { useFilters } from "@context/filters";
import { useAuth } from "@context/auth";
import { disableRowBasedOnDisableIf } from "@utils/utilsTable";
import { useParams } from "react-router-dom";
import { UseLabelTableStyles } from "../../hooks/useLabelTableStyles";
import { IconLabel } from "../IconLabel/Index";
import i18next from "i18next";

const Table: React.FC<TableInterface> = ({
  id,
  onChangeFilterForm,
  configurationTopToolbar,
  configurationActionForRow,
  totalPages = 0,
  enableMultiRowSelection,
  enableRowSelection,
  enableRowDrag,
  onRowSelectionChange,
  onDragChange,
  selectedRows,
  modalTitle,
  renamedActionsColumn,
  noSummarySelected,
  numberOfActions,
  isActivities,
  renderCell,
  setActionData,
  renderActionIcon,
  renderActionIconBottomToolbar,
  renderActionUnderBottomToolbar,
  hiddenFilters = false,
  enableSelectAll = false,
  getPagination,
  ...props
}) => {
  // const defaultColumnOrder = columns.map(({ accessorKey }) => accessorKey);
  const {
    columns,
    data,
    enableRowActions = true,
    renderRowActions,
    renderTopToolbar,
  } = props;

  const { user } = useAuth();
  const params = useParams();
  const currentLanguage = i18next.language;
  const empty = {
    accessorKey: "emptyData",
    header: "",
    visibility: true,
    text: {
      it: "Nessun dato presente",
      en: "No data to show",
    },
  };

  const switchLang = (lang: string) => {
    switch (currentLanguage) {
      case "en":
        return MRT_Localization_EN;
      case "it":
        return MRT_Localization_IT;
    }
  };

  const defaultPinning = useCallback(() => {
    // mrt-row-select
    let defaultVisibility: { left: string[]; right: string[] } = {
      left: [],
      right: [],
    };
    enableRowSelection && defaultVisibility?.left?.unshift("mrt-row-select");
    return defaultVisibility;
  }, [enableRowSelection]);

  const defaultVisibility = (columns: MRT_ColumnDef<any>[]) => {
    let defaultVisibility = {};
    defaultVisibility = columns.reduce((tot: any, elem: any) => {
      // if exist a visibility param, the column is displayed accorging to the visibility params
      // else all the column is visible
      if (
        elem &&
        Object.keys(elem)?.length > 0 &&
        elem.hasOwnProperty("visibility")
      ) {
        tot = { ...tot, [elem.accessorKey]: elem.visibility };
        return tot;
      } else {
        tot = { ...tot, [elem.accessorKey]: true };
        return tot;
      }
    }, defaultVisibility);
    return defaultVisibility;
  };

  const defaultOrder = useCallback(
    (columns: MRT_ColumnDef<any>[]) => {
      const optionalColumns = ["mrt-row-expand"];
      enableRowActions && optionalColumns.unshift("mrt-row-actions");
      enableRowSelection && optionalColumns.unshift("mrt-row-select");
      enableRowDrag && optionalColumns.unshift("mrt-row-drag");
      return [
        ...optionalColumns,
        ...columns.map(({ accessorKey }) => accessorKey),
      ];
    },
    [enableRowActions, enableRowDrag, enableRowSelection]
  );

  const [selectedInternalRows, setSelectedInternalRows] = useState<any>(
    selectedRows || undefined
  );
  const themeTable = useTheme();
  const { setHideHeader } = useHeader();
  const [columnPinning, setColumnPinning] = useState<any | undefined>(
    undefined
  );
  const [columnVisibility, setColumnVisibility] = useState<any | undefined>(
    undefined
  );
  const [columnOrder, setColumnOrder] = useState<any | undefined>(undefined);
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 20,
  });
  const { filters, setFilters } = useFilters();
  const [toolbarHeight, setToolbarHeight] = useState(40);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const { t: tableTranslate } = useTranslation("table");
  const { t: optionsTranslate, i18n } = useTranslation("input");
  const filterId = !["", null].includes(id) ? `${user?.id_user}_${id}` : "";

  useEffect(() => {
    if (filters[filterId]) {
      setPagination({
        pageIndex: filters[filterId].pageIndex || 0,
        pageSize: filters[filterId].pageSize || 20,
      });
    }

    if (!!isActivities) {
    }
  }, []);

  useEffect(() => {
    if (selectedInternalRows !== undefined) {
      onRowSelectionChange && onRowSelectionChange(selectedInternalRows);
    }
  }, [selectedInternalRows]);

  useEffect(() => {
    if (JSON.stringify(selectedRows) !== JSON.stringify(selectedInternalRows)) {
      setSelectedInternalRows(selectedRows);
    }
  }, [selectedRows]);

  const additionalTraduction = {
    hideAll: tableTranslate("hide-all"),
    sortByColumnAsc: tableTranslate("sort-col-asc"),
    sortByColumnDesc: tableTranslate("sort-col-desc"),
    filterByColumn: tableTranslate("filter-col"),
    pinToLeft: tableTranslate("pin-left"),
    pinToRight: tableTranslate("pin-right"),
    unpin: tableTranslate("unpin"),
    hideColumn: tableTranslate("hide-column"),
    actions: renderActionIcon
      ? renderActionIcon
      : tableTranslate(renamedActionsColumn || "actions"),
  };

  useEffect(() => {
    if (columns.length > 0) {
      const configTable = getLocalStorage(filterId);
      const config: { [x: string]: any } = configTable
        ? JSON.parse(configTable)
        : undefined;
      if (configTable !== "") {
        if (JSON.stringify(config.columns) !== JSON.stringify(columns)) {
          const diffColumns: any = columns.map((col) => {
            const findColumn = Object.keys(config.columnVisibility).find(
              (el) => el === col.accessorKey
            );
            if (!!findColumn) {
              return { ...col, visibility: true };
            }
            return col;
          });
          const defVisibility = defaultVisibility(diffColumns);
          const defOrder = defaultOrder(diffColumns);
          setColumnVisibility(defVisibility);
          setColumnOrder(defOrder);
        } else {
          setColumnVisibility(config.columnVisibility);
          setColumnOrder(config.columnOrder);
        }
        setColumnPinning(config.columnPinning);
      } else {
        const defPinning = defaultPinning();
        const defVisibility = defaultVisibility(
          columns as MRT_ColumnDef<any>[]
        );
        const defOrder = defaultOrder(columns as MRT_ColumnDef<any>[]);
        setColumnVisibility(defVisibility);
        setColumnOrder(defOrder);
        setColumnPinning(defPinning);
      }
    }
  }, [id, defaultOrder, columns, defaultPinning]);

  useEffect(() => {
    if (getPagination) {
      getPagination(pagination);
    }
    if (onChangeFilterForm) {
      setFilters(filterId, {
        ...filters[filterId],
        ...pagination,
      });
      onChangeFilterForm({
        ...filters[filterId],
        ...pagination,
      });
    }
  }, [pagination]);

  useEffect(() => {
    if (columnOrder || columnPinning || columnVisibility) {
      const newConfig = {
        columnPinning,
        columnVisibility,
        columnOrder,
        columns,
      };
      if (!["", null].includes(filterId)) {
        setLocalStorage(filterId, newConfig, 1);
      }
    }
  }, [columnPinning, columnVisibility, columnOrder, filterId, columns]);

  const handleChangeFilters = useCallback(
    (filters: any) => {
      const paginationInit = {
        pageIndex: 0,
        pageSize: pagination.pageSize || 20,
      };
      setPagination(paginationInit);
      if (onChangeFilterForm) {
        // setFilters(filters);
        onChangeFilterForm({ ...filters, ...paginationInit });
      }
    },
    [onChangeFilterForm, pagination]
  );

  const formatColumnsFilter = useCallback(() => {
    const columnsOrdered: any[] = [];
    let i = 0;
    columnOrder &&
      columnOrder.forEach((column: any) => {
        if (columns.some((elem) => elem.accessorKey === column)) {
          const foundedIndex = columns.findIndex(
            (elem) => elem.accessorKey === column
          );
          columnsOrdered[i] = columns[foundedIndex];
          i = i + 1;
        }
      });
    return [
      ...columnsOrdered.filter((column) =>
        columnVisibility !== undefined &&
        columnVisibility &&
        columnVisibility[column.accessorKey] === false
          ? false
          : true
      ),
    ].filter((col) => col.hasFilter);
  }, [columnOrder, columnVisibility, columns]);

  const customRenderBottomToolbar = ({
    table,
  }: {
    table: any;
  }): React.ReactNode => {
    if (renderActionIconBottomToolbar) {
      return (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            alignContent: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ display: "flex", paddingInlineStart: "1rem" }}>
            {renderActionIconBottomToolbar}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <Box sx={{ width: "100%" }}>
              <MRT_BottomToolbar table={table} />
            </Box>
            {renderActionUnderBottomToolbar && (
              <Box sx={{ padding: "1rem" }}>
                {renderActionUnderBottomToolbar}
              </Box>
            )}
          </Box>
        </Box>
      );
    }

    return <MRT_BottomToolbar table={table} />;
  };
  const customRenderTopToolbar = ({
    table,
  }: {
    table: any;
  }): React.ReactNode => {
    let formattedColumns = formatColumnsFilter();
    return (
      <CustomTopToolbar
        id={id}
        columns={formattedColumns}
        onChangeFilterForm={handleChangeFilters}
        configurationTopToolbar={configurationTopToolbar}
        table={table}
        hiddenFilters={hiddenFilters}
        setHideHeader={setHideHeader}
        counterElements={!!enableRowSelection}
        onToolbarChange={(height) => setToolbarHeight(height)}
        modalTitle={modalTitle}
        noSummarySelected={noSummarySelected}
      />
    );
  };

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow && [
      ...configurationActionForRow,
    ];
    // Check if inside action's config is present disableIf and check cases passed inside it
    configRowNew = disableRowBasedOnDisableIf(configRowNew, row, {
      corporateID: params?.id,
    });
    /* if (configRowNew && configRowNew?.find((el:any) => el.type === "popper")) {
      if (
        row.original.publish_date &&
        dayjs(row.original.publish_date).isAfter(dayjs())
      ) {
        configRowNew =
          configRowNew &&
          configRowNew?.map((el:any) => {
            switch (el.key) {
              case "enrollment":
                return { ...el, disabled: true };
              case "enrollment-editions":
                return { ...el, disabled: true };
              case "subscribed":
                return { ...el, disabled: true };
              case "unsubscribed":
                return { ...el, disabled: true };
              default:
                return el;
            }
          });
      }
    } */

    return (
      enableRowActions && [
        <CustomRowAction
          key={row.id}
          row={row}
          configurationActionForRow={configRowNew}
          setHideHeader={setHideHeader}
          setActionData={setActionData}
        />,
      ]
    );
  };

  const renderColumnActionsMenuItems = () => [<CustomLi></CustomLi>];

  const cellRender = (cel: any, value: any, row: any) => {
    const { type, optionsKey } = cel;
    switch (type) {
      // case "time": {
      //   const date = dayjs(new Date(value)?.toLocaleString()).format(
      //     "DD/MM/YYYY HH:mm",
      //   );
      //   return value ? <Row>{date || "-"}</Row> : "-";
      // }
      case "dateTime": {
        const date =
          new Date(value)?.toLocaleString()?.split(",") &&
          new Date(value)?.toLocaleString()?.split(",")[0];
        return value ? <Row>{date || "-"}</Row> : "-";
      }
      case "dateTimeYear": {
        return <Row>{value}</Row>;
      }
      case "boolean": {
        // cel.optionsKey === "yes-no"
        return <Checkbox disabled checked={value as boolean} />;
      }
      case "select-checkbox":
      case "multiCheckbox": {
        if (
          Array.isArray(value) &&
          value.find((obj: any) => typeof obj === "object")
        ) {
          const allValues = value
            .map((val: any) => {
              if (typeof val === "string") {
                return val;
              }
              return val.title || val.label || val.id || "-";
            })
            .join(", ");

          return (
            <Tooltip title={allValues}>
              <Row>{allValues}</Row>
            </Tooltip>
          );
        }
        const mapping = Array.isArray(value) ? value?.join(", ") : value;
        return <Row>{mapping || "-"}</Row>;
      }
      case "chips": {
        const mapping = value?.join(", ");
        return <Row>{mapping || "-"}</Row>;
      }
      case "select": {
        const val = value;
        if ([null, undefined].includes(value)) {
          return <Row>-</Row>;
        }
        if (val?.label) {
          return val.label as any;
        } else {
          let options =
            OptionsConfig.find((option) => {
              return option.key === optionsKey;
            })?.options || [];
          if (options) {
            if (
              optionsKey === "LearningObjectType" &&
              !options.includes(value.toString().toUpperCase())
            ) {
              value = "else";
            }

            const translateLabel = optionsTranslate(
              `options.${optionsKey}.${value.toString().toLowerCase()}`
            );

            return cel.dynamicColorData ? (
              <Row style={UseLabelTableStyles(cel.dynamicColorData, val)}>
                {translateLabel}
              </Row>
            ) : (
              <Row>{translateLabel}</Row>
            );
          } else {
            return (
              <Row>
                {value
                  ? (value as { [key: string]: string })[i18n.language]
                  : "-"}
              </Row>
            );
          }
        }
      }
      case "association-with-id": {
        if (Array.isArray(value)) {
          return (
            <Row>
              {value
                .map((val: any) => {
                  if (typeof val === "string") {
                    return val;
                  }
                  return val.id || "-";
                })
                .join(", ")}
            </Row>
          );
        } else if (value && Object.keys(value)?.length > 0) {
          return <Row>{value?.id || "-"}</Row>;
        }
        break;
      }
      case "association":
      case "select_with_association": {
        if (Array.isArray(value)) {
          return (
            <Row>
              {value
                .map((val: any) => {
                  if (typeof val === "string") {
                    return val;
                  }
                  return val.title || val.label || val.id || "-";
                })
                .join(", ")}
            </Row>
          );
        } else if (value && Object.keys(value)?.length > 0) {
          const allValue: any[] = Object.keys(value).map(
            (key: string) => value[key]
          );
          return (
            <Row>
              {value?.title || value?.label || value?.id || "-"}
              {/* {
              allValue
                .map((val: any) => {
                  if (typeof val === "string") {
                    return val;
                  }
                  return val?.title || val?.id || "-";
                })
                .join("")} */}
            </Row>
          );
        }
        break;
      }
      case "multi-text": {
        if (cel.accessorKey === "uog") {
          let valueUOG = value.map((el: any) => el.uog);
          return <Row>{valueUOG.length > 0 ? valueUOG : "-"}</Row>;
        }
        break;
      }
      case "switch":
      case "selectRow": {
        if (!renderCell) return "";
        return renderCell(cel, value, row);
      }
      case "iconText": {
        return (
          <IconLabel
            row={row.original}
            icon={cel.dynamicIcon}
            value={
              value
                ? Array.isArray(value)
                  ? value.length > 0
                    ? value.join(", ")
                    : " - "
                  : value
                : " - "
            }
          />
        );
      }
      default:
        if (typeof value === "object" && value !== null) {
          return value.name || "";
        }
        if (
          Array.isArray(value) &&
          value.find((obj: any) => typeof obj === "object")
        ) {
          const allValues = value
            .map((val: any) => {
              if (typeof val === "string") {
                return val;
              }
              return val.title || val.label || val.id || "-";
            })
            .join(", ");

          return (
            <Tooltip title={allValues}>
              <Row>{allValues}</Row>
            </Tooltip>
          );
        } else {
          return cel.dynamicColorData ? (
            <Tooltip title={value}>
              <Row style={UseLabelTableStyles(cel.dynamicColorData, value)}>
                {value
                  ? Array.isArray(value)
                    ? value.length > 0
                      ? value.join(", ")
                      : " - "
                    : capitalize(value)
                  : " - "}
              </Row>
            </Tooltip>
          ) : (
            <Tooltip title={value}>
              <Row>
                {value
                  ? Array.isArray(value)
                    ? value.length > 0
                      ? value.join(", ")
                      : " - "
                    : value
                  : " - "}
              </Row>
            </Tooltip>
          );
        }
    }
  };

  // Normalize column pinning putting action rows column, if pinned, always
  // in the last position of the Array so the Table will render it as last
  // item and it will not change width
  const normalizeColumnPinning = () => {
    if (
      columnPinning?.left.length > 1 &&
      columnPinning?.left.includes("mrt-row-actions")
    ) {
      const newLeftPinnig = columnPinning.left.filter(
        (element: string) => element !== "mrt-row-actions"
      );
      newLeftPinnig.push("mrt-row-actions");
      columnPinning.left = newLeftPinnig;
      return columnPinning;
    }

    return columnPinning;
  };

  const getSizeColumns = () => {
    let sizeActionsColumn = 0;
    if (configurationActionForRow && configurationActionForRow?.length > 0) {
      sizeActionsColumn = configurationActionForRow?.length * 40;
    } else {
      if (numberOfActions) sizeActionsColumn = numberOfActions * 40;
    }
    return sizeActionsColumn > 120
      ? sizeActionsColumn
      : renderActionIcon
      ? 200
      : 120;
  };

  const handleFullScreenStatus = (status: any) => {
    setIsFullScreen(status);
    setHideHeader && setHideHeader(status);
  };

  return (
    <MaterialReactTable
      localization={{
          ...switchLang(currentLanguage),
        ...additionalTraduction,
      }}
      enableRowOrdering={enableRowDrag}
      muiTableBodyRowDragHandleProps={({ table }) => ({
        onDragEnd: () => {
          const { draggingRow, hoveredRow } = table.getState();
          const dragClone: any = JSON.parse(JSON.stringify(draggingRow));
          const hovClone: any = JSON.parse(JSON.stringify(hoveredRow));
          const draggingElements = dragClone && dragClone.original;
          const hoveredElements = hovClone && hovClone.original;
          if (hoveredRow && draggingRow) {
            onDragChange && onDragChange(draggingElements, hoveredElements);
          }
        },
      })}
      enablePinning
      enableSelectAll={enableSelectAll}
      enableMultiRowSelection={enableMultiRowSelection}
      enableRowSelection={enableRowSelection}
      onRowSelectionChange={setSelectedInternalRows}
      enableColumnFilters={true}
      enableColumnResizing={true}
      enableTopToolbar={true}
      defaultColumn={{ minSize: getSizeColumns() }}
      enableColumnOrdering={true}
      onColumnPinningChange={setColumnPinning}
      onColumnVisibilityChange={setColumnVisibility}
      onColumnOrderChange={setColumnOrder}
      onPaginationChange={setPagination}
      // initialState={{ pagination }}
      rowCount={totalPages}
      state={{
        pagination,
        isLoading: !data,
        ...{ columnPinning: columnPinning ? normalizeColumnPinning() : {} },
        ...{ columnVisibility: columnVisibility || [] },
        ...{ columnOrder: columnOrder },
        rowSelection: selectedRows || {},
        showProgressBars: !data,
        showGlobalFilter: true,
        isFullScreen,
      }}
      manualPagination
      renderColumnActionsMenuItems={renderColumnActionsMenuItems}
      enableDensityToggle={false}
      enableRowActions={enableRowActions}
      onIsFullScreenChange={(status) => handleFullScreenStatus(status)}
      muiTableContainerProps={() => ({
        sx: {
          height: isFullScreen
            ? `calc(100vh - 56px - ${
                toolbarHeight < 550 ? toolbarHeight : 550
              }px)`
            : "unset",
          ".MuiTable-root": {
            opacity: columns.length > 0 ? 1 : 0,
            transition: "opacity 0.8s",
            ".MuiTableHead-root": {
              display: data?.length > 0 ? "table-row-group" : "none",
            },
            ".MuiTableBody-root": {
              tr: {
                "td:not(:last-child)": {
                  display: data?.length > 0 ? "table-cell" : "none",
                },
              },
            },
          },
        },
      })}
      renderRowActions={
        configurationActionForRow && columns?.length > 0 && data?.length > 0
          ? customRenderRowActions
          : renderRowActions
      }
      renderBottomToolbar={customRenderBottomToolbar}
      renderTopToolbar={
        configurationTopToolbar ? customRenderTopToolbar : renderTopToolbar
      }
      muiTableHeadCellProps={() => ({
        sx: {
          ".Mui-TableHeadCell-Content-Wrapper": {
            whiteSpace: "nowrap",
            textTransform: "capitalize",
          },
        },
      })}
      muiBottomToolbarProps={() => ({
        sx: {
          ".MuiToolbar-root button": {
            color: themeTable.table?.actionColor,
          },
          ".MuiToolbar-root svg": { color: themeTable.table?.actionColor },
        },
      })}
      muiTableHeadCellColumnActionsButtonProps={() => ({
        sx: {
          color: themeTable.table?.actionColor,
        },
      })}
      muiToolbarAlertBannerProps={({ table }) => ({
        sx: {
          color: themeTable.table?.actionColor,
        },
      })}
      {...props}
      columns={
        data?.length > 0
          ? columns.map((cel: any) => {
              return {
                ...cel,
                Cell: (obj: any) => {
                  const { cell, row } = obj;
                  if (cel.Cell) {
                    return <Row>{cel.Cell(obj)}</Row>;
                  }
                  return cellRender(cel || "", cell.getValue(), row);
                },
              };
            })
          : [empty]
      }
      data={
        data?.length > 0
          ? data
          : [{ emptyData: (empty.text as any)[i18n.language] }]
      }
    />
  );
};

export default Table;
