export enum MethodType {
  "GET" = "GET",
  "POST" = "POST",
  "PUT" = "PUT",
  "DELETE" = "DELETE",
  "PATCH" = "PATCH",
}
export interface ResponseInterface<T> {
  data?: T;
  message?: string;
  status: number;
  headers?: any;
}

export enum KeyURL {
  login = "login",
  achievements = "achievements",
  permissions = "permissions",
  smartconfigurator = "smartconfigurator",
  corporate = "corporate",
  corporateCustomFields = "corporateCustomFields",
  corporates = "corporates",
  categories = "categories",
  organization_tree = "organizationTree",
  corporate_organization_tree = "corporateOrganizationTree",
  groups = "groups",
  staticGroups = "staticGroups",
  dynamicGroups = "dynamicGroups",
  activities = "activities",
  activities_digited = "activitiesDigited",
  catalogCorporateAssociation = "catalogCorporateAssociation",
  catalogCorporateDisassociation = "catalogCorporateDisassociation",
  catalogDomainAssociation = "catalogDomainAssociation",
  catalogDomainDisassociation = "catalogDomainDisassociation",
  learningObjectsStandAlone = "learningObjectsStandAlone",
  skills = "skills",
  locations = "locations",
  sites = "sites",
  rooms = "rooms",
  visibility_cones = "visibilityCones",
  initiative = "initiative",
  topics = "topics",
  path = "path",
  path_digited = "pathDigited",
  customer = "customer",
  digited_users = "digitedUsers",
  digited_users_campaign_delete = "digitedUsersCampaignDelete",
  digited_campaign = "digitedCampaign",
  digited_tipology_campaign = "digitedTipologyCampaign",
  digited_role_user = "digitedRoleUser",
  digited_campaign_levels = "digitedCampaignLevels",
  digited_detail_campaign = "digitedDetailCampaign",
  digited_detail_campaign_add = "digitedDetailCampaignAdd",
  digited_campaign_report = "digitedCampaignReport",
  digited_detail_campaign_learner = "digitedDetailCampaignLearner",
  digited_detail_campaign_learner_confirm_gap = "digitedDetailCampaignGapManagementLearnerGap",
  digited_detail_campaign_learner_delete_knowledge = "digitedDetailCampaignLearnerDeleteKnowledge",
  digited_delete_detail_campaign = "digitedDeleteDetailCampaign",
  digited_campaign_users = "digitedUsersCampaign",
  reminder_users_campaign = "reminderUsersCampaign",
  faculties = "faculties",
  users = "users",
  courses = "courses",
  courses_digited = "coursesDigited",
  educatinal_formats_digited = "educationalFormatsDigited",
  catalogCategoryAssociation = "catalogCategoryAssociation",
  catalogTopicAssociation = "catalogTopicAssociation",
  catalogCategoryDisassociation = "catalogCategoryDisassociation",
  catalogTopicDisassociation = "catalogTopicDisassociation",
  usersDomain = "usersDomain",
  static_groups = "static-groups",
  library = "library",
  external_vendors = "external-vendors",
  editorialText = "editorialText",
  enrollment = "enrollment",
  getEnrollmentGroupLo = "getEnrollmentGroupLo",
  group_initiative_association = "group_initiative_association",
  user_initiative_association = "user-initiative-association",
  course_category_association = "course-category-association",
  path_category_association = "path-category-association",
  activity_category_association = "activity-category-association",
  course_topic_association = "course-topic-association",
  activity_topic_association = "activity-topic-association",
  path_topic_association = "path-topic-association",
  physical_editions = "physical-editions",
  virtual_editions = "virtual-editions",
  learning_catalogue = "learning-catalogue",
  badge_id = "badges",
}

export enum KeyUserURL {
  getFaculties = "getFaculties",
  postFaculty = "postFaculty",
  postFaculties = "postFaculties",
  putFaculties = "putFaculties",
  putFacultiesDownload = "putFacultiesDownload",
  getCustomer = "getCustomer",
  postCustomer = "postCustomer",
  postCustomers = "postCustomers",
  postCampaign = "postCampaign",
  putCustomer = "putCustomer",
  deleteCustomer = "deleteCustomer",
  putCustomerStatus = "putCustomerStatus",
  corporateLanguages = "corporateLanguages",
  getCorporateCustomFields = "getCorporateCustomFields",
  postCorporateCustomFields = "postCorporateCustomFields",
  getFacultiesDownload = "getFacultiesDownload",
  getCustomersDownload = "getCustomersDownload",
  getCustomersDownloadTemplate = "getCustomersDownloadTemplate",
}

export enum KeyPathsURL {
  getPaths = "getPaths",
  postPaths = "postPaths",
  putPath = "putPath",
  getActivityAssociation = "getActivityAssociation",
  getCoursesAssociation = "getCoursesAssociation",
}

export enum KeyCertificateBadgesURL {
  getBadges = "getBadges",
  getCertificates = "getCertificates",
  getPlaceholders = "getPlaceholders",
  getIssuedCertificatesZip = "getIssuedCertificatesZip",
  getIssuedCertificate = "getIssuedCertificate",
  getSkillBook = "getSkillBook",
  getCertificate = "getCertificate",
  getCourseType = "getCourseType",
  postApproveRequest = "postApproveRequest",
  postSkillBook = "postSkillBook",
}
export enum KeyEnrollmentURL {
  getEnrollment = "getEnrollment",
  postEnrollment = "postEnrollment",
  deleteEnrollment = "deleteEnrollment",
  patchEnrollment = "patchEnrollment",
  postEnrollmentMassive = "postEnrollmentMassive",
  postGroupEnrollmentMassive = "postGroupEnrollmentMassive",
  deleteGroupEnrollmentMassive = "deleteGroupEnrollmentMassive",
  getEnrollmentMassive = "getEnrollmentMassive",
  getEnrollHistory = "getEnrollHistory",
  getEnrollEditionDetail = "getEnrollEditionDetail",
  getEnrollEdition = "getEnrollEdition",
  downloadEnrolledEdition = "downloadEnrolledEdition",
}

export enum KeyEnrollmentRemainderURL {
  getEnrollmentRemainder = "getEnrollmentRemainder",
  postEnrollmentRemainder = "postEnrollmentRemainder",
  postEnrollmentMassiveRemainder = "postEnrollmentMassiveRemainder",
}

export enum KeyEnrollmentSuggestedURL {
  getEnrollmentSuggested = "getEnrollmentSuggested",
  postEnrollmentSuggested = "postEnrollmentSuggested",
  postEnrollmentMassiveSuggested = "postEnrollmentMassiveSuggested",
}

export enum KeyActivitiesURL {
  getActivities = "getActivities",
  getFormats = "getFormats",
  postActivities = "postActivities",
  putActivities = "putActivities",
}

export enum KeySmartConfiguratorURL {
  getDownloadSmartConfigurator = "getDownloadSmartConfigurator",
  getSmartConfigurator = "getSmartConfigurator",
  getDetailSmartConfigurator = "getDetailSmartConfigurator",
  patchDetailSmartConfigurator = "patchDetailSmartConfigurator",
  postSmartconfigurator = "postSmartconfigurator",
  genericSmartConfigurator = "genericSmartConfigurator",
  smartconfigurator_brandingAsset = "smartconfigurator-brandingAsset",
  smartconfigurator_template_mail = "smartconfigurator-template-mail",
  smartconfigurator_notifications = "smartconfigurator-notifications",
  smartconfiguratorDownloadTemplate = "smartconfiguratorDownloadTemplate",
  getSmartConfiguratorMaintenancePage = "getSmartConfiguratorMaintenancePage",
  postSmartConfiguratorMaintenancePage = "postSmartConfiguratorMaintenancePage",
}
export enum KeyPermissionsURL {
  getSelftPermissions = "getSelftPermissions",
  postPermissions = "postPermissions",
  putPermissions = "putPermissions",
}

export enum KeyEditionURL {
  getEditions = "getEditions",
  postEditions = "postEditions",
  deleteVirtual = "deleteVirtual",
  deletePhysical = "deletePhysical",
  uploadParticipants = "uploadParticipants",
  downloadParticipants = "downloadParticipants",
  downloadTemplateParticipants = "downloadTemplateParticipants",
}

export enum keyOnboarding {
  postWelcomemail = "postWelcomemail",
}

export enum keyMediaManager {
  uploadAdminTheme = "uploadAdminTheme",
}

export enum keySessionManager {
  getSessionByID = "getSessionByID",
  postSessionByID = "postSessionByID",
  getSessionCognito = "getSessionCognito",
}
export enum keyAssociatedLo {
  loAssociated = "loAssociated",
}

export enum keyWrapper {
  postWrapper = "postWrapper",
}

export enum keyCommunity {
  getTerms = "getTerms",
  getDiscussion = "getDiscussion",
  getForum = "getForum",
}

export enum keyUsersWaiting {
  getUsersWaiting = "getUsersWaiting",
  patchUsersApproveDeny = "patchUsersApproveDeny",
}

export enum keyIndustries {
  getIndustries = "getIndustries",
  postIndustries = "postIndustries",
  putIndustries = "putIndustries",
}

export enum keyProfessions {
  getProfessions = "getProfessions",
  postProfessions = "postProfessions",
  putProfessions = "putProfessions",
  putProfessionsObsolete = "putProfessionsObsolete",
}

export enum keyJobs {
  getJobs = "getJobs",
  postJobs = "postJobs",
  putJobs = "putJobs",
  putJobsObsolete = "putJobsObsolete",
  getAllJobs = "getAllJobs",
}

export enum keyKnowledgeAreas {
  getKnowledgeAreas = "getKnowledgeAreas",
  postKnowledgeAreas = "postKnowledgeAreas",
  putKnowledgeAreas = "putKnowledgeAreas",
  putKnowledgeAreasObsolete = "putKnowledgeAreasObsolete",
}

export enum keyKnowledgeDomains {
  getKnowledgeDomainsAll = "getKnowledgeDomainsAll",
  getKnowledgeDomainsId = "getKnowledgeDomainsId",
  getKnowledgeDomains = "getKnowledgeDomains",
  postKnowledgeDomains = "postKnowledgeDomains",
  putKnowledgeDomains = "putKnowledgeDomains",
  putKnowledgeDomainsObsolete = "putKnowledgeDomainsObsolete",
}
export enum keyCapabilities {
  getCapabilities = "getCapabilities",
  postCapabilities = "postCapabilities",
  putCapabilities = "putCapabilities",
  putCapabilitiesObsolete = "putCapabilitiesObsolete",
}

export enum KeyknowledgeDomainJobs {
  getKnowledgeDomainJob = "getKnowledgeDomainJob",
  postKnowledgeDomainJob = "postKnowledgeDomainJob",
  deleteKnowledgeDomainJob = "deleteKnowledgeDomainJob",
}

export enum keySurvey {
  getSurvey = "getSurvey",
  postSurvey = "postSurvey",
  putSurvey = "putSurvey",
  deleteSurvey = "deleteSurvey",
}

export enum keyNotifications {
  getNotifications = "getNotifications",
  getNotification = "getNotification",
  getEvents = "getEvents",
  getUsersExcludedTemplate = "getUsersExcludedTemplate",
  postNotifications = "postNotifications",
  putNotifications = "putNotifications",
  deleteNotification = "deleteNotification",
}

export enum keySuggestionsKnowledgeDomains {
  getAllKnowledgeDomainsSuggestion = "getAllKnowledgeDomainsSuggestion",
  getAllKnowledgeDomainsNoPaginationSuggestion = "getAllKnowledgeDomainsNoPaginationSuggestion",
  postUploadKnowledgeDomainsSuggestion = "postUploadKnowledgeDomainsSuggestion",
  getTemplateKnowledgeDomainsSuggestion = "getTemplateKnowledgeDomainsSuggestion",
  postKnowledgeDomainsSuggestion = "postKnowledgeDomainsSuggestion",
  deteleKnowledgeDomainSuggestion = "deteleKnowledgeDomainSuggestion",
}
export enum keySuggestionsCapabilities {
  getAllCapabilitiesSuggestion = "getAllCapabilitiesSuggestion",
  getAllCapabilitiesNoPaginationSuggestion = "getAllCapabilitiesNoPaginationSuggestion",
  postUploadCapabilitiesSuggestion = "postUploadCapabilitiesSuggestion",
  getTemplateCapabilitiesSuggestion = "getTemplateCapabilitiesSuggestion",
  postCapabilitiesSuggestion = "postCapabilitiesSuggestion",
  deteleCapabilitySuggestion = "deteleCapabilitySuggestion",
}

export enum keyRuntastic {
  getRuntastic = "getRuntastic",
}
export enum keyTeachersAreaExercise {
  getExercises = "getExercises",
  getSingleExercise = "getSingleExercise",
  putExercise = "putExercise",
  postExercise = "postExercise",
  deleteExercise = "deleteExercise",
  getUploadUrl = "getUploadUrl",
}
export enum keyTeachersAreaExerciseAssociation {
  getExercisesAssociation = "getExercisesAssociation",
  putExerciseAssociation = "putExerciseAssociation",
  getEditionsAssociable = "getEditionsAssociable",
}
export enum keyTeachersAreaRevision {
  getRevisions = "getRevisions",
  getSingleRevisions = "getSingleRevisions",
  putRevisions = "putRevisions",
  presignedRevision = "presignedRevision",
  downloadRevisionsFile = "downloadRevisionsFile",
  getrevisionsByEditions = "getrevisionsByEditions",
}
export enum keyTeachersAreaReport {
  getReport = "getReport",
  getSingleReport = "getSingleReport",
  putReport = "putReport",
}

export enum keyEditionTOJ {
  getTOJ = "getTOJ",
  postTOJ = `postTOJ`,
  getEditTOJ = `getEditTOJ`,
  putTOJ = `putTOJ`,
  getTOJManage = "getTOJManage",
  putTOJManage = "putTOJManage",
  deleteEnrollmentTOJ = "deleteEnrollmentTOJ",
  getUserEnrollCourseTOJ = "getUserEnrollCourseTOJ",
  postEnrollToTOJEdition = "postEnrollToTOJEdition",
  getEnrolledUserTOJEdition = "getEnrolledUserTOJEdition",
  getManageEnrollTOJ = "getManageEnrollTOJ",
  postManageEnrollTOJ = "postManageEnrollTOJ",
}

export enum keySelfSignupRules {
  getRule = "getRule",
  postRule = "postRule",
  putRule = "putRule",
  deleteRule = "deleteRule",
}

export enum keySelfSignupSteps {
  getFields = "getFields",
  getForm = "getForm",
  postForm = "postForm",
  putForm = "putForm",
}

export enum keyCorporates {
  getCorporate = "getCorporate",
  putCorporate = "putCorporate",
  getInitiativesUsers = "getInitiativesUsers",
  getDynamicGroupsUsers = "getDynamicGroupsUsers",
  corporateLanguages = "corporateLanguages",
}

export enum keyGroupsEnrolledCatalog {
  getGroupsEnrolledWithEnrollInfo = "getGroupsEnrolledWithEnrollInfo",
  getLOEnrolledWithEnrollInfo = "getLOEnrolledWithEnrollInfo",
  getGroupsEnrolledToLO = "getGroupsEnrolledToLO",
}

export enum keySoftwareVersioning {
  getVersioning = "getVersioning",
  getVersioningTagging = "getVersioningTagging",
  deleteVersioningTagging = "deleteVersioningTagging",
  postVersioningTagging = "postVersioningTagging",
  patchVersioningTagging = "patchVersioningTagging",
  patchVersioningTaggingAssociation = "patchVersioningTaggingAssociation",
  patchVersioningTaggingDeassociation = "patchVersioningTaggingDeassociation",
}

export type TotalKeyURL =
  | keyCorporates
  | keySessionManager
  | KeyURL
  | KeyUserURL
  | KeyEnrollmentRemainderURL
  | KeyEnrollmentSuggestedURL
  | KeyEnrollmentURL
  | KeyPathsURL
  | KeyCertificateBadgesURL
  | KeyActivitiesURL
  | KeySmartConfiguratorURL
  | KeyPermissionsURL
  | KeyEditionURL
  | keyOnboarding
  | keyMediaManager
  | keyAssociatedLo
  | keyWrapper
  | keyCommunity
  | keyUsersWaiting
  | keyIndustries
  | keyProfessions
  | keyJobs
  | keyKnowledgeAreas
  | keyKnowledgeDomains
  | keyCapabilities
  | KeyknowledgeDomainJobs
  | keyRuntastic
  | "getLanguages"
  | "smartconfiguratorTranslation"
  | "uploadMultilanguage"
  | "downloadMultilanguage"
  | "initiativeLanguages"
  | "corporateLanguages"
  | keySuggestionsKnowledgeDomains
  | keySuggestionsCapabilities
  | keySurvey
  | keyNotifications
  | keyTeachersAreaExercise
  | keyTeachersAreaExerciseAssociation
  | keyTeachersAreaRevision
  | keyTeachersAreaReport
  | keyEditionTOJ
  | keySelfSignupRules
  | keySelfSignupSteps
  | keySoftwareVersioning
  | keyGroupsEnrolledCatalog;
