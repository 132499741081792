import { Box, capitalize, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useResetFilters } from "../../hooks/useFiltersProvider";

export const TableTabs = ({
  tabs,
  goToFinalTab,
  setGoToFinalTab,
  setExternalSelectedTab,
}: {
  tabs: any;
  goToFinalTab?: boolean;
  setGoToFinalTab?: any;
  setExternalSelectedTab?: any;
}) => {
  const pathName = window.location.pathname.split("/");

  const isCreateManagementPage =
    pathName[pathName.length - 1] == "create-management";

  const isCompletationPage =
    pathName[pathName.length - 1] == "completation-campaign";

  const [selectedTab, setSelectedTab] = useState(() => {
    if (isCreateManagementPage || isCompletationPage) {
      const savedTab = localStorage.getItem("selectedTab");
      return savedTab !== null ? parseInt(savedTab, 10) : 0;
    }
    return 0;
  });

  const handleTabChange = (newValue: number) => {
    setSelectedTab(newValue);
    if (isCreateManagementPage || isCompletationPage) {
      localStorage.setItem("selectedTab", newValue.toString());
    }
    setExternalSelectedTab && setExternalSelectedTab(newValue);
  };

  useResetFilters();

  useEffect(() => {
    if (goToFinalTab) goFinalTab();
  }, [goToFinalTab]);

  // Effetto per sincronizzare `selectedTab` con il valore iniziale
  useEffect(() => {
    if (isCreateManagementPage || isCompletationPage) {
      const savedTab = localStorage.getItem("selectedTab") || "0";
      setExternalSelectedTab(parseInt(savedTab, 10));
    }
  }, [isCreateManagementPage, isCompletationPage]);

  const goFinalTab = () => {
    setSelectedTab(tabs.length - 1);
    setExternalSelectedTab && setExternalSelectedTab(tabs.length - 1);
    setGoToFinalTab(false);
  };

  return (
    <Box>
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "#696969",
          zIndex: 2000,
          background: "#151515",
        }}
      >
        <Tabs
          value={selectedTab}
          aria-label="table tabs"
          sx={{
            "& .MuiTab-root": {
              color: "white", // colore testo bianco
              textTransform: "none", // mantiene il testo con la capitalizzazione originale
              fontSize: "1.5rem", // dimensione del testo aumentata a 1.5rem
              position: "relative", // per consentire il posizionamento dell'indicatore
              "&.Mui-selected::after": {
                content: '""',
                display: "block",
                width: "100%",
                height: "3px",
                backgroundColor: "white",
                position: "absolute",
                bottom: 0,
                left: 0,
              },
            },
            "& .MuiTab-root.Mui-selected": {
              color: "white", // colore testo bianco quando selezionato
            },
            "& .MuiTabs-indicator": {
              display: "none", // Nascondi l'indicatore predefinito
            },
          }}
        >
          {tabs.map((tab: any, index: number) => {
            // Ottieni la chiave e il valore dell'oggetto
            const label = Object.keys(tab)[0];

            if (!label) return null;
            return (
              <Tab
                key={index}
                label={capitalize(label)}
                onClick={() => handleTabChange(index)} // Gestione del clic per cambiare scheda
              />
            );
          })}
        </Tabs>
      </Box>
      <Box>
        {tabs[selectedTab] &&
          tabs[selectedTab][Object.keys(tabs[selectedTab])[0]]}
      </Box>
    </Box>
  );
};
