import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalDownloadUploadCst from "@components/ModalDownloadUploadCst";
import { ActionUploadDownloadIterface } from "@components/ModalDownloadUploadCst/types";
import { fetcher } from "src/services/config";
import { MethodType } from "src/services/type";
import useSWRMutation from "swr/mutation";
import AnimationFadeIn from "@components/AnimationFadeIn";
import CustomRowAction from "@components/Table/components/CustomRowAction";
import { useHeader } from "@context/header";
import ButtonBackPage from "../../../../../components/ButtonBackPage";
import { useFetchTable } from "../../../../../hooks/useFetchDataTable";
import { Campaign } from "../CreateManagement/types";
import { TableTabs } from "../../../../../components/TableTabs/Index";
import { useParams } from "react-router-dom";
import { useAuthProvider } from "../../../../../context/auth/hooks";

const CompletationCampaign = () => {
  const { user } = useAuthProvider();
  const { t } = useTranslation(["training-needs-create-page"]);
  const { setHideHeader } = useHeader();
  const [openUpload, setOpenUpload] = useState<ActionUploadDownloadIterface>();
  const [campaignDeleted, setCampaignDeleted] = useState<Campaign>();
  const [selectedTab, setSelectedTab] = useState(0);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const { id } = useParams();

  const isLearner = selectedTab == 1;

  const dataCampaign = useFetchTable(
    `${ServicesURL.digitedTipologyCampaign}`
      .replace("{campaignType}", "UOG")
      .replace("{corporateId}", `${id}`) +
      (user && user.id_user
        ? `&campaign_state=IN_PROGRESS&user_id=${user.id_user}`
        : ""),
    PagesID["corporate.completation.campaign"],
  );

  const dataCampaignLearner = useFetchTable(
    `${ServicesURL.digitedTipologyCampaign}`
      .replace("{campaignType}", "LEARNER")
      .replace("{corporateId}", `${id}`) +
      (user && user.id_user
        ? `&campaign_state=IN_PROGRESS&user_id=${user.id_user}`
        : ""),
    PagesID["corporate.completation.campaign"],
  );

  const typeCampaign = selectedTab == 0 ? dataCampaign : dataCampaignLearner;

  const { trigger: triggerDeleteCampaign } = useSWRMutation(
    `${ServicesURL.digitedCampaign}`,
    (url: string, { arg }: { arg: { users_id: any } }) => {
      return fetcher<any>(
        `${url}/${arg.users_id.campaign_id}`,
        MethodType.DELETE,
      );
    },
  );

  const handleDelete = React.useCallback(async () => {
    if (campaignDeleted) {
      setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
      try {
        await triggerDeleteCampaign({
          users_id: campaignDeleted,
        });
        const newData = [
          ...typeCampaign?.dataTable?.output.filter(
            (el: any) => el.campaign_id !== campaignDeleted.campaign_id,
          ),
        ];
        typeCampaign.mutate &&
          typeCampaign.mutate({
            ...typeCampaign.dataTable,
            ...{ output: newData },
          });
        setOpenToast({ s: StatusEnum.OK, message: "" });
      } catch (error) {
        setOpenToast(undefined);
        console.error(error);
      }
    }
  }, [typeCampaign.dataTable, campaignDeleted, triggerDeleteCampaign]);

  const configurationActionForRow = React.useMemo(() => {
    return (row: any) => {
      let dynamicIconColor = "";
      return typeCampaign.actionForRow
        ?.filter((action: any) => {
          switch (action.type) {
            case "visibility":
              return !(
                isLearner &&
                row.original &&
                row.original.campaign_state !== "COMPLETED" &&
                row.original.hr_state !== "COMPLETED"
              );
            case "collectionResults":
              return (
                selectedTab == 1 &&
                row.original.campaign_state == "COMPLETED" &&
                row.original.campaign_role == "HR"
              );
            case "confirm":
              return !(
                isLearner &&
                ((row.original &&
                  row.original.campaign_state === "COMPLETED") ||
                  (row.original && row.original.hr_state === "COMPLETED"))
              );
            default:
              return true;
          }
        })
        .map((action: any) => {
          switch (action.type) {
            case "visibility":
              return {
                ...action,
                url: isLearner ? "detail-users" : action.url,
              };
            case "confirm":
              dynamicIconColor = row.original?.hr_state || "";
              return {
                ...action,
                dynamicIconColor,
                url: isLearner ? "gap-management-learner" : action.url,
              };
            default:
              return action;
          }
        });
    };
  }, [typeCampaign.actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew: any = configurationActionForRow(row) && [
      ...configurationActionForRow(row),
    ];

    if (
      row.original.status === "active" ||
      row.original.status === "suspended"
    ) {
      return [
        <CustomRowAction
          key={row.id}
          row={row}
          configurationActionForRow={[
            ...configRowNew.filter(
              (el: any) => ["activate"].findIndex((key) => key === el.key) < 0,
            ),
          ]}
          setHideHeader={setHideHeader}
        />,
      ];
    }

    if (row.original.status === "terminated") {
      configRowNew = configRowNew.map((action: any) => {
        if (!action?.key && !["delete", "activate"].includes(action?.key)) {
          return { ...action, disabled: true };
        } else {
          return { ...action };
        }
      });
      return [
        <CustomRowAction
          key={row.id}
          row={row}
          configurationActionForRow={[
            ...configRowNew.filter(
              (el: any) => ["delete"].findIndex((key) => key === el.key) < 0,
            ),
          ]}
          setHideHeader={setHideHeader}
        />,
      ];
    }

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={[
          ...configRowNew.filter(
            (el: any) =>
              ["delete", "activate"].findIndex((key) => key === el.key) < 0,
          ),
        ]}
      />,
    ];
  };

  const tabs = [
    {
      "Per Unità Organizzativa": (
        <Table
          id={PagesID["corporate.completation.campaign"]}
          data={typeCampaign?.dataTable?.output}
          columns={typeCampaign.columns || []}
          onChangeFilterForm={typeCampaign.handleFetchData}
          configurationActionForRow={typeCampaign.actionForRow}
          configurationTopToolbar={typeCampaign.actionsTopToolbar}
          renderRowActions={customRenderRowActions}
          totalPages={typeCampaign.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
        />
      ),
    },
    {
      "Per Learner": (
        <Table
          id={PagesID["corporate.completation.campaign"]}
          data={typeCampaign?.dataTable?.output}
          columns={typeCampaign.columns || []}
          onChangeFilterForm={typeCampaign.handleFetchData}
          configurationActionForRow={typeCampaign.actionForRow}
          configurationTopToolbar={typeCampaign.actionsTopToolbar}
          renderRowActions={customRenderRowActions}
          totalPages={typeCampaign.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
        />
      ),
    },
  ];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          // title={`${dataDetail?.name} - ${t("hero-title")}`}
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <TableTabs setExternalSelectedTab={setSelectedTab} tabs={tabs} />
        </Box>
      </Container>
      <ModalDownloadUploadCst
        open={!!openUpload}
        setOpen={() => {
          setOpenUpload(undefined);
        }}
        title={openUpload?.labelTranslate || ""}
        data={openUpload}
      />

      <ModalConfirmsCst
        open={!!campaignDeleted}
        title={`${t("delete-campaign", { ns: "modal" })}`}
        description={`${t("confirm-delete-campaign", { ns: "modal" })}: ${
          campaignDeleted && campaignDeleted?.campaign_name
        }`}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setCampaignDeleted(undefined);
          setOpenToast(undefined);
        }}
        onActionConfirmed={() => campaignDeleted && handleDelete()}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default CompletationCampaign;
