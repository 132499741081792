import React, { useEffect, useState } from "react";
import { Box, Container } from "@mui/material";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import AddEditFormCst from "@components/AddEditFormCst";
import Breadcrumb from "@components/Breadcrumb";
import { useTranslation } from "react-i18next";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import ButtonBackPage from "../../../../../../components/ButtonBackPage";
import { useParams } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { axiosInstance } from "../../../../../../services/config";
import { useFetchDataDetail } from "../../../../../../hooks/useFetchDetail";

const AddLearnerCampaign: React.FC = () => {
  const { t } = useTranslation(["training-needs-campaign-page"]);
  const { t: tModal } = useTranslation(["modal"]);
  const { id } = useParams();

  const { columns } = useFetchDataDetail(
    `${ServicesURL.digitedTipologyCampaign}`
      .replace("{campaignType}", "LEARNER")
      .replace("{corporateId}", `${id}`),
    PagesID["corporate.learner.campaign.add"],
  );
  const [dataGroup, setDataGroup] = useState([]);
  const [dataUog, setDataUog] = useState<string[]>([]);

  const filteredVisibilityColumn = columns?.filter(
    (el: any) => el.visibility !== false,
  );

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();

  const { trigger: addCampaign } = useSWRMutation(
    ServicesURL.digitedCampaign + `?corporate_id=${id}`,
    (url: string, arg: { arg: any }) => {
      return () => axiosInstance.post(`${url}`, arg.arg);
    },
  );

  const { trigger: getGroups } = useSWRMutation(
    `${ServicesURL.groups.replace(":idCorporate", `${id}`)}/groups`,
    (url: string, arg: { arg: any }) => {
      return () => axiosInstance.get(`${url}`, arg.arg);
    },
  );

  const { trigger: getUog } = useSWRMutation(
    `${ServicesURL.corporateOrganizationTree.replace(
      "{corporateId}",
      `${id}`,
    )}`,
    (url: string) => {
      return () => axiosInstance.get(`${url}`);
    },
  );

  useEffect(() => {
    getGroups().then((resp: any) =>
      setDataGroup(resp.data && resp.data.output),
    );
    getUog().then((resp: any) => {
      const uniqueCodes: string[] = Array.from(
        new Set(
          resp.data.tree.map((item: any) => {
            return {
              code: item.code,
              description: item.description,
              parent_code: item.parent_code,
            };
          }),
        ),
      );
      setDataUog(uniqueCodes);
    });
  }, []);

  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });

    const buildLevels = (data: any) => {
      const levels: any = {};

      const rootItem = data.find((el: any) => el.parent_code == "NULL");

      function getLevelItems(parentCode: string, level: number) {
        if (!levels[level]) levels[level] = [];
        const items = data.filter(
          (item: any) => item.parent_code === parentCode,
        );

        if (items.length === 0) return;

        levels[level].push(...items);

        items.forEach((item: any) => getLevelItems(item.code, level + 1));
      }

      if (rootItem) {
        getLevelItems(rootItem.code, 1);
      } else {
        console.warn(
          "Nessun UOG con parent_code NULL trovato, pertanto non è possibile stilare la gerarchia",
        );
      }

      const fullLevels = {
        "0": [rootItem],
        ...levels,
      };

      return fullLevels;
    };

    const dataLevelsBuild = buildLevels(dataUog);

    const payloadLevelsManager = inputForm.section_manager && inputForm.section_manager.selectedLevels && inputForm.section_manager.selectedLevels
      .map((el: any) => dataLevelsBuild[el.name].map((data: any) => data.code))
      .flat();

    const payloadLevelsHR = inputForm.section_hr && inputForm.section_hr.selectedLevels && inputForm.section_hr.selectedLevels
      .map((el: any) => dataLevelsBuild[el.name].map((data: any) => data.code))
      .flat();

    const payloadGroupsManager = inputForm.section_manager && inputForm.section_manager.selectedGroups && inputForm.section_manager.selectedGroups.map(
      (el: any) => {
        return { id: el.id, group_type: el.type };
      },
    );
    const payloadGroupsHr = inputForm.section_hr && inputForm.section_hr.selectedGroups && inputForm.section_hr.selectedGroups.map(
      (el: any) => {
        return { id: el.id, group_type: el.type };
      },
    );

    // Mappatura dei dati di inputForm a objToSend
    const objToSend: any = {
      corporate_id: id,
      campaign_type: "LEARNER",
      name: inputForm["campaign_name"] || "",
      campaign_year: inputForm.campaign_year
        ? inputForm.campaign_year.$y.toString()
        : "",
      uog_aggregation_level: inputForm["uog_aggregation_level"],
      ...(inputForm.section_manager &&
        inputForm.section_manager.selectedGroups && {
          manager_level: {
            all_users: inputForm.section_manager.all_users,
            ...(payloadGroupsManager.length && { groups: payloadGroupsManager }),
            ...(payloadLevelsManager.length && {level_ids: payloadLevelsManager.flat()}),
          },
        }),
      ...(inputForm.section_hr &&
        inputForm.section_hr.selectedGroups && {
          hr_level: {
            all_users: inputForm.section_hr.all_users,
            ...(payloadGroupsHr.length && { groups: payloadGroupsHr }),
            ...(payloadLevelsHR.length && {level_ids: payloadLevelsHR.flat()}),
          },
        }),
    };

    try {
      await addCampaign(objToSend);
      setOpenToast({
        s: StatusEnum.OK,
        message: tModal("successfully-create-campaign", {
          campaign: inputForm["campaign_name"] || "",
        }),
      });
    } catch (error) {
      setOpenToast(undefined);
      console.error(error);
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("add.hero-title")}
          description={t("add.hero-description")}
        />
        <ButtonBackPage />
        {filteredVisibilityColumn && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              columns={filteredVisibilityColumn}
              onSubmit={onSubmit}
              options={dataGroup}
              optionsSelect={dataUog}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("hero-title-add")}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default AddLearnerCampaign;
