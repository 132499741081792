import React, { useState } from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";
import ButtonBackPage from "../../../../../../../components/ButtonBackPage";
import { TableTabs } from "../../../../../../../components/TableTabs/Index";
import { useFetchTable } from "../../../../../../../hooks/useFetchDataTable";
import { ServicesURL } from "../../../../../../../utils/utilsApi";
import { useParams } from "react-router-dom";
import CustomRowAction from "../../../../../../../components/Table/components/CustomRowAction";
import ButtonCst from "../../../../../../../components/ButtonCst";
import { downloadDataTable } from "../../../../../../../utils/utilsTable";
import ModalConfirmsCst from "../../../../../../../components/ModalConfirmsCst";
import { StatusEnum } from "../../../../../../../components/ModalConfirmsCst/types";
import { useAuthProvider } from "../../../../../../../context/auth/hooks";

const ViewDetailConfirm: React.FC = () => {
  const { user } = useAuthProvider();
  const { t } = useTranslation(["training-needs-details-user"]);
  const { t: tDownload } = useTranslation(["upload-component"]);
  const { id, idCampaign, idDetail } = useParams();

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();

  const isCompletationFlux = window.location.href.includes(
    "completation-campaign",
  );

  useResetFilters();

  const getKnowledge = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idDetail ?? idCampaign}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `KNOWLEDGE`) + `&confirmed=TRUE`,
    PagesID["corporate.campaign.detail.gap"],
  );

  const getCapacity = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idDetail ?? idCampaign}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `CAPACITY`) + `&confirmed=TRUE`,
    PagesID["corporate.campaign.detail.gap"],
  );

  let allKnowledge: any[] = [];
  if (
    getKnowledge &&
    getKnowledge?.dataTable?.output &&
    getCapacity &&
    getCapacity?.dataTable?.output
  ) {
    allKnowledge =
      [...getKnowledge?.dataTable?.output, ...getCapacity?.dataTable?.output] ||
      [];
  }

  const detailUsers = useFetchTable(
    `${ServicesURL.digitedUsersCampaign}`.replace(
      "{campaignId}",
      `${idDetail ?? idCampaign}`,
    ) +
      `?status=COMPLETED` +
      `${user ? `&manager=${user.external_id}&role=LEARNER` : ""}`,
    PagesID["corporate.learner.campaign.gap.management.confirmed"],
  );

  const configurationActionForRow = React.useMemo(() => {
    return (row: any) => {
      return detailUsers.actionForRow?.map((action: any) => {
        switch (action.type) {
          case "visibility": {
            return {
              ...action,
              useId: row.original.id,
            };
          }

          default:
            return action;
        }
      });
    };
  }, [detailUsers.actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew = configurationActionForRow(row) && [
      ...configurationActionForRow(row),
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };
  const configurationActionForRowGap = React.useMemo(() => {
    return (row: any) => {
      return getKnowledge.actionForRow?.map((action: any) => {
        switch (action.type) {
          case "visibility": {
            return {
              ...action,
              useId: row.original.id,
            };
          }

          default:
            return action;
        }
      });
    };
  }, [getKnowledge.actionForRow]);

  const customRenderRowActionsGap = ({
    row,
  }: {
    row: any;
  }): React.ReactNode => {
    let configRowNew = configurationActionForRowGap(row) && [
      ...configurationActionForRowGap(row),
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const tabs = [
    {
      "Per Learner": detailUsers && detailUsers?.dataTable?.output && (
        <Table
          enableRowActions={true}
          id={PagesID["corporate.learner.campaign.gap.management.confirmed"]}
          data={detailUsers.dataTable.output || []}
          renderRowActions={customRenderRowActions}
          columns={detailUsers.columns || []}
          onChangeFilterForm={detailUsers.handleFetchData}
          configurationTopToolbar={detailUsers.actionsTopToolbar}
          totalPages={detailUsers.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          renderActionIconBottomToolbar={<></>}
          renderActionUnderBottomToolbar={
            <ButtonCst
              onClick={() => downloadDataTable([detailUsers], setOpenToast)}
              sx={{ cursor: "pointer" }}
              variant="outlined"
              id={"download"}
            >
              {tDownload("download")}
            </ButtonCst>
          }
        />
      ),
    },
    {
      "Per Gap Formativi": (
        <Table
          enableRowActions={true}
          renderRowActions={customRenderRowActionsGap}
          id={PagesID["corporate.campaign.detail.gap"]}
          data={allKnowledge}
          columns={getKnowledge.columns || []}
          onChangeFilterForm={getKnowledge.handleFetchData}
          configurationTopToolbar={getKnowledge.actionsTopToolbar}
          totalPages={getKnowledge.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          renderActionIconBottomToolbar={<></>}
          renderActionUnderBottomToolbar={
            <ButtonCst
              onClick={() => downloadDataTable(allKnowledge, setOpenToast)}
              sx={{ cursor: "pointer" }}
              variant="outlined"
              id={"download"}
            >
              {tDownload("download")}
            </ButtonCst>
          }
        />
      ),
    },
  ];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`Visualizza Campagna per Learner`}
          description={t("hero-description")}
        />
        <ButtonBackPage goBack={2} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <TableTabs tabs={tabs} />
        </Box>
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={t("download-certificates.title", { ns: "modal" })}
        description={""}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        removeButtons={true}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default ViewDetailConfirm;
