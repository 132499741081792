import React, { useState } from "react";
import { Box, Checkbox, Container, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";

import { useNavigate, useParams } from "react-router-dom";

import ThumbDownOutlinedIcon from "@mui/icons-material/ThumbDownOutlined";
import ThumbUpOutlinedIcon from "@mui/icons-material/ThumbUpOutlined";

import useSWRMutation from "swr/mutation";
import ButtonBackPage from "../../../../../../../../components/ButtonBackPage";
import ButtonCst from "../../../../../../../../components/ButtonCst";
import ModalConfirmsCst from "../../../../../../../../components/ModalConfirmsCst";
import { StatusEnum } from "../../../../../../../../components/ModalConfirmsCst/types";
import CustomRowAction from "../../../../../../../../components/Table/components/CustomRowAction";
import { TableTabs } from "../../../../../../../../components/TableTabs/Index";
import { useFetchTable } from "../../../../../../../../hooks/useFetchDataTable";
import { fetcher } from "../../../../../../../../services/config";
import { MethodType } from "../../../../../../../../services/type";
import { ServicesURL } from "../../../../../../../../utils/utilsApi";

const TrainingGaps: React.FC = () => {
  const { t } = useTranslation(["training-needs-details-user"]);
  const { t: tModal } = useTranslation(["modal"]);
  const [checkBoxDataLike, setCheckBoxDataLike] = useState<any>([]);
  const [checkBoxDataDislike, setCheckBoxDataDislike] = useState<any>([]);
  const [goToFinalTab, setGoToFinalTab] = useState<any>(false);
  const [confirmModal, setConfirmModal] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);

  const { id, idDetail, idUser } = useParams();

  useResetFilters();

  const detailCampaignKnowledge = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idDetail}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `KNOWLEDGE`) +
      `&campaign_user_id=${idUser}&confirmed=TRUE`,
    PagesID["corporate.campaign.gap.management.confirmed.learner"]
  );

  const detailCampaignCapacity = useFetchTable(
    ServicesURL.digitedDetailCampaign
      .replace("{campaignId}", `${idDetail}`)
      .replace("{corporateId}", `${id}`)
      .replace("{typeKnowledge}", `CAPACITY`) +
      `&campaign_user_id=${idUser}&confirmed=TRUE`,
    PagesID["corporate.campaign.gap.management.confirmed.learner.capacity"]
  );

  const { trigger: triggerConfirmGap } = useSWRMutation(
    `${ServicesURL.digitedDetailCampaignGapManagementLearnerGap}`
      .replace("{campaignId}", `${idDetail}`)
      .replace("{userId}", `${idUser}`),
    (url: string, { arg }: any) => {
      return fetcher<any>(`${url}`, MethodType.PUT, arg);
    }
  );

  const handleConfirmModal = async () => {
    setConfirmModal({ s: StatusEnum.LOADING, message: "loading" });

    const checkBoxData = [...checkBoxDataDislike, ...checkBoxDataLike];

    const payload = checkBoxData.map((el: any) => {
      return { campaign_detail_id: el.id, liked: el.liked };
    });

    try {
      //@ts-ignore
      await triggerConfirmGap(payload);
      setConfirmModal({
        s: StatusEnum.OK,
        message: "",
      });
    } catch (error) {
      setConfirmModal({
        s: StatusEnum.KO,
        message: "",
      });
      console.error(error);
    }
  };

  const handleCheckBox = (row: any, type: string) => {
    let dataBox = type === "like" ? checkBoxDataLike : checkBoxDataDislike;
    let setData =
      type === "like" ? setCheckBoxDataLike : setCheckBoxDataDislike;
    if (dataBox && dataBox.some((el: any) => el.id === row.id)) {
      const removeData = dataBox.filter((el: any) => el.id !== row.id);

      setData(removeData);
    } else {
      const filteredData = {
        id: row.id,
        liked: type === "like" ? "YES" : "NO",
      };
      setData([...dataBox, filteredData]);
    }
  };

  const handleAllCheckBox = (type: string) => {
    const dataBox = type === "like" ? checkBoxDataLike : checkBoxDataDislike;
    const setData =
      type === "like" ? setCheckBoxDataLike : setCheckBoxDataDislike;

    const allDataBox = [
      ...detailCampaignCapacity.dataTable.output,
      ...detailCampaignKnowledge.dataTable.output,
    ];

    const dataBoxFiltered = dataBox.filter(
      (el: any) => el.status != "COMPLETED"
    );

    const dataFiltered = allDataBox.filter(
      (el: any) => el.status != "COMPLETED"
    );

    const differentDataBox =
      type === "like" ? checkBoxDataDislike : checkBoxDataLike;
    const updateData = dataFiltered.filter(
      (el: any) => !differentDataBox.some((user: any) => user.id === el.id)
    );

    if (dataBox && dataFiltered && dataBoxFiltered.length < updateData.length) {
      return setData(
        updateData.map((el: any) => {
          const filteredData = {
            id: el.id,
            liked: type === "like" ? "YES" : "NO",
          };
          return filteredData;
        })
      );
    }
    setData([]);
  };

  const configurationActionForRow = React.useMemo(() => {
    return (row: any) => {
      return detailCampaignKnowledge.actionForRow
        ?.filter((action: any) => action.type !== "visibility")
        .map((action: any) => {
          return {
            ...action,
            useId: row.original.id,
          };
        });
    };
  }, [detailCampaignKnowledge.actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew = configurationActionForRow(row) && [
      ...configurationActionForRow(row),
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
        setCheckBoxData={handleCheckBox}
        checkBoxData={[checkBoxDataLike, checkBoxDataDislike]}
      />,
    ];
  };

  const tabs = [
    {
      [t("knowledge")]: detailCampaignKnowledge && (
        <Table
          id={PagesID["corporate.campaign.learner.edit.gap"]}
          data={detailCampaignKnowledge?.dataTable?.output}
          columns={detailCampaignKnowledge.columns || []}
          onChangeFilterForm={detailCampaignKnowledge.handleFetchData}
          renderRowActions={customRenderRowActions}
          configurationTopToolbar={detailCampaignKnowledge.actionsTopToolbar}
          totalPages={
            detailCampaignKnowledge.dataTable?.metadata?.total_elements
          }
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          renderActionIcon={
            <div
              style={{
                width: "170px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <FormControlLabel
                style={{ margin: "0 2px" }}
                labelPlacement="top"
                label={t("all")}
                control={
                  <Checkbox
                    sx={{
                      background: "#84293c",
                      border: "1px solid #84293c",
                      borderRadius: 0,
                      padding: 0,
                    }}
                    disabled
                    checkedIcon={
                      <ThumbUpOutlinedIcon
                        sx={{
                          fill: "#151515 !important",
                          background: "#f43e63",
                        }}
                      />
                    }
                    icon={
                      <ThumbUpOutlinedIcon
                        sx={{
                          fill: "#151515 !important",
                        }}
                      />
                    }
                  />
                }
              />
              <FormControlLabel
                style={{ margin: "0 2px" }}
                labelPlacement="top"
                label={t("all")}
                control={
                  <Checkbox
                    sx={{
                      border: "1px solid #84293c",
                      borderRadius: 0,
                      padding: 0,
                    }}
                    disabled
                    checkedIcon={
                      <ThumbDownOutlinedIcon
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <ThumbDownOutlinedIcon
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                  />
                }
              />
            </div>
          }
          renderActionIconBottomToolbar={
            <div
              style={{
                width: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                paddingTop: "10px",
              }}
            >
              <FormControlLabel
                style={{ margin: 0 }}
                control={
                  <Checkbox
                    sx={{
                      background: "#84293c",
                      border: "1px solid #84293c",
                      borderRadius: 0,
                      padding: 0,
                    }}
                    checked={
                      checkBoxDataLike.length + checkBoxDataDislike.length ==
                      detailCampaignKnowledge?.dataTable?.output.length
                    }
                    checkedIcon={
                      <ThumbUpOutlinedIcon
                        fontSize="large"
                        sx={{
                          fill: "#151515 !important",
                          margin: 0,
                          background: "#f43e63",
                        }}
                      />
                    }
                    icon={
                      <ThumbUpOutlinedIcon
                        fontSize="large"
                        sx={{
                          fill: "#151515 !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox("like")}
                  />
                }
                label=""
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      border: "1px solid #84293c",
                      borderRadius: 0,
                      padding: 0,
                    }}
                    checked={
                      checkBoxDataLike.length + checkBoxDataDislike.length ==
                      detailCampaignKnowledge?.dataTable?.output.length
                    }
                    checkedIcon={
                      <ThumbDownOutlinedIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <ThumbDownOutlinedIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox("dislike")}
                  />
                }
                label=""
              />
            </div>
          }
          renderActionUnderBottomToolbar={
            <ButtonCst
              onClick={() => setGoToFinalTab(true)}
              sx={{ cursor: "pointer" }}
              disabled={
                detailCampaignKnowledge?.dataTable?.output?.every((item:any) =>
                  checkBoxDataLike.some((like:any) => like.id === item.id) ||
                  checkBoxDataDislike.some((dislike:any) => dislike.id === item.id)
                ) === false
              }
              variant="contained"
              id={"continueButton"}
            >
              {t("confirm-success-results")}
            </ButtonCst>
          }
        />
      ),
    },
    {
      [t("capacity")]: detailCampaignCapacity && (
        <Table
          id={PagesID["corporate.campaign.gap.management.capacity"]}
          data={detailCampaignCapacity?.dataTable?.output}
          columns={detailCampaignCapacity.columns || []}
          onChangeFilterForm={detailCampaignCapacity.handleFetchData}
          renderRowActions={customRenderRowActions}
          configurationTopToolbar={detailCampaignCapacity.actionsTopToolbar}
          totalPages={
            detailCampaignCapacity.dataTable?.metadata?.total_elements
          }
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          renderActionIcon={
            <div
              style={{
                width: "170px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <FormControlLabel
                style={{ margin: "0 2px" }}
                labelPlacement="top"
                label={t("all")}
                control={
                  <Checkbox
                    disabled
                    sx={{
                      background: "#84293c",
                      border: "1px solid #84293c",
                      borderRadius: 0,
                      padding: 0,
                    }}
                    checkedIcon={
                      <ThumbUpOutlinedIcon
                        sx={{
                          fill: "#151515 !important",
                          background: "#f43e63",
                        }}
                      />
                    }
                    icon={
                      <ThumbUpOutlinedIcon
                        sx={{
                          fill: "#151515 !important",
                        }}
                      />
                    }
                  />
                }
              />
              <FormControlLabel
                style={{ margin: "0 2px" }}
                labelPlacement="top"
                label={t("all")}
                control={
                  <Checkbox
                    disabled
                    sx={{
                      border: "1px solid #84293c",
                      borderRadius: 0,
                      padding: 0,
                    }}
                    checkedIcon={
                      <ThumbDownOutlinedIcon
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <ThumbDownOutlinedIcon
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                  />
                }
              />
            </div>
          }
          renderActionIconBottomToolbar={
            <div
              style={{
                width: "180px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
                paddingTop: "10px",
              }}
            >
              <FormControlLabel
                style={{ margin: 0 }}
                control={
                  <Checkbox
                    sx={{
                      background: "#84293c",
                      border: "1px solid #84293c",
                      borderRadius: 0,
                      padding: 0,
                    }}
                    checked={
                      checkBoxDataLike.length + checkBoxDataDislike.length ==
                      detailCampaignKnowledge?.dataTable?.output.length
                    }
                    checkedIcon={
                      <ThumbUpOutlinedIcon
                        fontSize="large"
                        sx={{
                          fill: "#151515 !important",
                          margin: 0,
                          background: "#f43e63",
                        }}
                      />
                    }
                    icon={
                      <ThumbUpOutlinedIcon
                        fontSize="large"
                        sx={{
                          fill: "#151515 !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox("like")}
                  />
                }
                label=""
              />
              <FormControlLabel
                control={
                  <Checkbox
                    sx={{
                      border: "1px solid #84293c",
                      borderRadius: 0,
                      padding: 0,
                    }}
                    checked={
                      checkBoxDataLike.length + checkBoxDataDislike.length ==
                      detailCampaignKnowledge?.dataTable?.output.length
                    }
                    checkedIcon={
                      <ThumbDownOutlinedIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <ThumbDownOutlinedIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox("dislike")}
                  />
                }
                label=""
              />
            </div>
          }
          renderActionUnderBottomToolbar={
            <ButtonCst
              onClick={() => setOpenConfirmModal(true)}
              sx={{ cursor: "pointer" }}
              disabled={
                checkBoxDataLike.length + checkBoxDataDislike.length !=
                detailCampaignCapacity?.dataTable?.output.length +
                  detailCampaignKnowledge?.dataTable?.output.length
              }
              variant="contained"
              id={"buttonConfirm"}
            >
              {t("confirm-success-results")}
            </ButtonCst>
          }
        />
      ),
    },
  ];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage goBack={2} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <TableTabs
            tabs={tabs}
            goToFinalTab={goToFinalTab}
            setGoToFinalTab={setGoToFinalTab}
          />
        </Box>
        <ModalConfirmsCst
          open={openConfirmModal}
          title={`${tModal("confirm-passes-results")}`}
          description={`${tModal("want-confirm-passes-results")}`}
          onCallBackClosed={() => {
            setConfirmModal(undefined);
            setOpenConfirmModal(false);
          }}
          onActionConfirmed={() => handleConfirmModal()}
          status={confirmModal}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default TrainingGaps;
