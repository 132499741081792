import React from "react";
import {
  Box,
  Grid,
  Switch,
  Typography,
  FormControlLabel,
  useTheme,
  CircularProgress,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { useNotificationState } from "@pages/Contents/Corporate/Notifications/context/NotificationContext";
import { useTranslation } from "react-i18next";
import { ListNotificationType } from "../../../../../../interfaces/Common";
import {
  getFlagKey,
  getUploadKey,
  // handleFileChange,
} from "@pages/Contents/Corporate/Notifications/utils/utils";

const Step2 = () => {
  const theme = useTheme();
  const { t } = useTranslation(["notification-page"]);
  const { state, dispatch } = useNotificationState(); //GLOBAL STATE NOTIFICATIONS
  const [downloadState, setDownloadState] = React.useState<any>({
    email: "",
    applicative: "",
    push_mobile: "",
  });

  const handleSwitchChange = (type: ListNotificationType) => {
    //ON SWITCH CHANGE
    const flagKey = getFlagKey(type);
    const uploadKey = getUploadKey(type);
    const isSwitchOn = !state[flagKey];

    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: flagKey, value: isSwitchOn },
    }); //SET CATEGORY FLAG VALUE

    if (!isSwitchOn) {
      //IF SWITCH OFF REMOVE FILES UPLOADED IF EXIST FOR CURRENT CATEGORY
      dispatch({
        type: "SET_INPUT_VALUES",
        payload: { key: uploadKey, value: [] },
      });
    }
  };

  const handleDownload = async (type: ListNotificationType) => {
    //TODO: DOWNLOAD FILE
    setDownloadState((prevState: any) => ({
      ...prevState,
      [type]: "downloading",
    }));

    await new Promise((resolve) => setTimeout(resolve, 2000));

    setDownloadState((prevState: any) => ({
      ...prevState,
      [type]: "completed",
    }));
  };

  const getButtonText = (type: ListNotificationType) => {
    //DOWNLOAD BUTTON LABEL
    switch (downloadState[type]) {
      case "downloading":
        return t("in_progress");
      case "completed":
        return t("downloaded");
      default:
        return t("download");
    }
  };

  const getButtonIcon = (type: ListNotificationType) => {
    //BUTTON DOWNLOAD ICON
    switch (downloadState[type]) {
      case "downloading":
        return <CircularProgress size={20} />;
      case "completed":
        return <CheckCircleOutlineIcon />;
      default:
        return <DownloadIcon />;
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: "16px",
        borderRadius: "3px",
        border: `1px solid ${theme.palette.divider}`,
      }}
    >
      <Typography variant="body1" gutterBottom>
        {t("template")}
      </Typography>
      <Grid container spacing={3}>
        {["email", "applicative", "push_mobile"].map((type) => {
          const typeKey = type.toLowerCase() as ListNotificationType;
          const flagKey = getFlagKey(typeKey);
          // const uploadKey = getUploadKey(typeKey);

          return (
            <Grid item xs={12} key={type}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                  marginBottom: "16px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    minWidth: "150px",
                    marginRight: "16px",
                    fontWeight: "bold",
                  }}
                >
                  {t(type)}
                </Typography>
                <Box
                  sx={{
                    width: "50%", //TODO: REMOVE
                  }}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        checked={state[flagKey]}
                        onChange={() => handleSwitchChange(typeKey)}
                      />
                    }
                    label={`${t("activate_sending_with")} ${t(typeKey)}`}
                    labelPlacement="end"
                    sx={{ marginRight: "auto", paddingBottom: "8px" }}
                  />
                  {/*DOWNLOAD & UPLOAD TEMPLATES TEMPORARY HIDED */}
                  {/*<Box*/}
                  {/*  sx={{*/}
                  {/*    flex: 1,*/}
                  {/*    display: "flex",*/}
                  {/*    justifyContent: "flex-end",*/}
                  {/*    gap: 1,*/}
                  {/*  }}*/}
                  {/*>*/}
                  {/*  <Button*/}
                  {/*    sx={{*/}
                  {/*      marginRight: "8px",*/}
                  {/*      backgroundColor: theme.palette.background.paper,*/}
                  {/*      color: theme.palette.primary.main,*/}
                  {/*      border: `1px solid ${theme.palette.primary.main}`,*/}
                  {/*      flex: 1,*/}
                  {/*      whiteSpace: "nowrap",*/}
                  {/*      borderRadius: "3px",*/}
                  {/*    }}*/}
                  {/*    startIcon={getButtonIcon(typeKey)}*/}
                  {/*    onClick={() => handleDownload(typeKey)}*/}
                  {/*    disabled={!state[flagKey]}*/}
                  {/*  >*/}
                  {/*    {getButtonText(typeKey)}*/}
                  {/*  </Button>*/}
                  {/*  <Button*/}
                  {/*    component="label"*/}
                  {/*    sx={{*/}
                  {/*      backgroundColor: theme.palette.primary.main,*/}
                  {/*      color: theme.palette.text.primary,*/}
                  {/*      flex: 1,*/}
                  {/*      borderRadius: "3px",*/}
                  {/*      whiteSpace: "nowrap",*/}
                  {/*    }}*/}
                  {/*    disabled={!state[flagKey]}*/}
                  {/*  >*/}
                  {/*    {!!state[uploadKey] && state[uploadKey].length !== 0*/}
                  {/*      ? t("uploaded")*/}
                  {/*      : t("upload")}*/}
                  {/*    <VisuallyHiddenInput*/}
                  {/*      type="file"*/}
                  {/*      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>*/}
                  {/*        handleFileChange(e, typeKey, state, dispatch)*/}
                  {/*      }*/}
                  {/*    />*/}
                  {/*  </Button>*/}
                  {/*</Box>*/}
                </Box>
              </Box>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default Step2;
