import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  FormControlLabel,
  Switch,
  FormControl,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  Box,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@mui/material";
import { useTranslation } from "react-i18next";

interface SectionFormProps {
  col: {
    accessorKey: string;
    sectionData: {
      label: string;
      levels?: boolean;
      groups?: boolean;
    };
    role: string;
  };
  inputForm: any;
  options?: any;
  optionsLevels?: any;
  setInputForm: React.Dispatch<React.SetStateAction<any>>;
  error?: any;
  errorMessage?: string;
  fullLevels?: any;
}

const SectionFormNewCampaign: React.FC<SectionFormProps> = ({
  col,
  inputForm,
  setInputForm,
  options = [],
  optionsLevels = [],
  error = false,
  errorMessage = "",
}) => {
  const { t } = useTranslation(["training-needs-campaign-page"]);
  const { label } = col.sectionData;
  const sectionState = inputForm[col.accessorKey] || {};
  const enabled = sectionState.enabled || false;
  const selectedOption = sectionState.selectedOption || t("add.all");
  const selectedLevels: any[] = sectionState.selectedLevels || [];
  const selectedGroups: any[] = sectionState.selectedGroups || [];
  const [availableLevelsForHR, setAvailableLevelsForHR] =
    useState<any>(optionsLevels);

  const groups = options;
  const levels = optionsLevels;

  const isExistManager = inputForm?.section_manager?.selectedLevels.length;

  useEffect(() => {
    if (isExistManager && label === "HR") {
      const managerLevels = inputForm?.section_manager?.selectedLevels || [];
  
      const maxSelected = Math.max(
        ...managerLevels.map((level: any) => parseInt(level.name)),
      );
  
      const filteredManagerLevels = levels
        .filter((el: any) => parseInt(el.name) <= maxSelected)
        .sort((a: any, b: any) => parseInt(a.name) - parseInt(b.name));
  
      const validLevelsForHR = [...filteredManagerLevels];
  
      // Aggiorna `availableLevelsForHR` solo se necessario
      if (JSON.stringify(validLevelsForHR) !== JSON.stringify(availableLevelsForHR)) {
        setAvailableLevelsForHR(validLevelsForHR);
      }
  
      // Non resettare `selectedLevels` se ci sono già valori validi
      const prevSelectedLevels = inputForm[col.accessorKey]?.selectedLevels || [];
      const validSelectedLevels = prevSelectedLevels.filter((level: any) =>
        validLevelsForHR.some((validLevel: any) => validLevel.name === level.name),
      );
  
      if (JSON.stringify(validSelectedLevels) !== JSON.stringify(prevSelectedLevels)) {
        setInputForm((prevState: any) => ({
          ...prevState,
          [col.accessorKey]: {
            ...prevState[col.accessorKey],
            selectedLevels: validSelectedLevels, // Mantieni i livelli validi
          },
        }));
      }
    }
  
    if (!isExistManager) {
      setAvailableLevelsForHR(optionsLevels);
    }
  }, [levels, isExistManager, inputForm.section_manager?.selectedLevels, availableLevelsForHR]);


  const handleManagerLevelsChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string[];

    const isAllLevelsSelected =
      (value[0] && value[0].toLowerCase() == "tutti i livelli") ||
      (value[value.length - 1] &&
        value[value.length - 1].toLowerCase() == "tutti i livelli");

    setInputForm((prevState: any) => {
      const prevSectionState = prevState[col.accessorKey] || {};
      const allLevels = levels;

      if (isAllLevelsSelected) {
        if (
          !prevSectionState.selectedLevels ||
          prevSectionState.selectedLevels.length !== allLevels.length
        ) {
          return {
            ...prevState,
            [col.accessorKey]: {
              ...prevSectionState,
              ...sectionState,
              selectedLevels: allLevels,
            },
            section_hr: {
              ...prevState?.section_hr,
              selectedLevels: [],
            },
          };
        }

        return {
          ...prevState,
          [col.accessorKey]: {
            ...prevSectionState,
            ...sectionState,
            selectedLevels: [],
          },
          section_hr: {
            ...prevState?.section_hr,
            selectedLevels: [],
          },
        };
      }

      const updatedLevels = value
        .map((desc) => {
          const level = levels.find((lvl: any) => lvl.name === desc);
          if (level) {
            return level;
          } else {
            console.error(`Livello non trovato: ${desc}`);
            return null;
          }
        })
        .filter((lvl) => lvl !== null);

      return {
        ...prevState,
        [col.accessorKey]: {
          ...prevSectionState,
          ...sectionState,
          selectedLevels: updatedLevels,
        },
        section_hr: {
          ...prevState?.section_hr,
          selectedLevels: [],
        },
      };
    });
  };

  const handleHRLevelsChange = (
    event: React.ChangeEvent<{ value: unknown }>,
  ) => {
    const value = event.target.value as string[];

    const isAllLevelsSelected =
      (value[0] && value[0].toLowerCase() == "tutti i livelli") ||
      (value[value.length - 1] &&
        value[value.length - 1].toLowerCase() == "tutti i livelli");

    setInputForm((prevState: any) => {
      const prevSectionState = prevState[col.accessorKey] || {};
      const allLevels = levels;

      if (isAllLevelsSelected) {
        const expectedLength = isExistManager
          ? availableLevelsForHR.length
          : allLevels.length;

        if (
          !prevSectionState.selectedLevels ||
          prevSectionState.selectedLevels.length !== expectedLength
        ) {
          return {
            ...prevState,
            [col.accessorKey]: {
              ...prevSectionState,
              ...sectionState,
              selectedLevels: isExistManager ? availableLevelsForHR : allLevels,
            },
          };
        }

        // Deseleziona tutti i livelli se erano già tutti selezionati
        return {
          ...prevState,
          [col.accessorKey]: {
            ...prevSectionState,
            ...sectionState,
            selectedLevels: [],
          },
        };
      }

      const updatedLevels = value
        .map((desc) => {
          const level = levels.find((lvl: any) => lvl.name === desc);

          if (level) {
            return level;
          } else {
            console.error(`Livello non trovato: ${desc}`);
            return null;
          }
        })
        .filter((lvl) => lvl !== null);
      return {
        ...prevState,
        [col.accessorKey]: {
          ...prevSectionState,
          ...sectionState,
          selectedLevels: updatedLevels,
        },
      };
    });
  };

  return (
    <Grid
      key={col.accessorKey}
      item
      xs={12}
      sm={12}
      paddingLeft={2}
      paddingBottom={6}
    >
      {/* Header and switch */}
      <Grid
        item
        xs={12}
        sm={12}
        paddingBottom={2}
        container
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="h6" component="h6">
          {error && (
            <FormHelperText error disabled={error}>
              {errorMessage}
            </FormHelperText>
          )}
          {label} *
        </Typography>
        <FormControlLabel
          control={
            <Switch
              checked={enabled}
              onChange={() => {
                const newEnabled = !enabled;
                setInputForm((prevState: any) => {
                  const prevSectionState = prevState[col.accessorKey] || {};
                  if (label == "HR")
                    return {
                      ...prevState,
                      [col.accessorKey]: {
                        ...prevSectionState,
                        enabled: newEnabled,
                        selectedOption: newEnabled ? "carica" : t("add.all"),
                        selectedLevels: newEnabled ? selectedLevels : [],
                        selectedGroups: newEnabled ? selectedGroups : [],
                      },
                    };
                  else
                    return {
                      ...prevState,
                      [col.accessorKey]: {
                        ...prevSectionState,
                        enabled: newEnabled,
                        selectedOption: newEnabled ? "carica" : t("add.all"),
                        selectedLevels: newEnabled ? selectedLevels : [],
                        selectedGroups: newEnabled ? selectedGroups : [],
                      },
                      section_hr: {
                        ...prevState?.section_hr, // Verifica che section_hr esista
                        selectedLevels: [], // Svuota i livelli per HR
                      },
                    };
                });
              }}
              required
            />
          }
          label={`${t("add.activate-switch")} ${label}`}
        />
      </Grid>

      {/* Select for levels */}
      {col.sectionData.levels && (
        <Grid item xs={12} sm={6} paddingBottom={2}>
          <FormControl fullWidth disabled={!enabled}>
            <Select
              disabled={error && !optionsLevels || inputForm[col.accessorKey] && !inputForm[col.accessorKey].enabled}
              error={error}
              labelId={`${label}-levels-label`}
              multiple
              value={selectedLevels.map((lvl) => lvl.name)} // Modifica per usare le descrizioni
              onChange={(e: any) => {
                if (label === "Manager") {
                  handleManagerLevelsChange(e);
                } else {
                  handleHRLevelsChange(e);
                }
              }}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return (
                    <Typography>
                      {t("add.selected-levels", { role: label })}
                    </Typography>
                  );
                }
                return (selected as any[]).join(", ");
              }}
              displayEmpty
            >
              <MenuItem value={t("add.all-levels")}>
                <Checkbox
                  checked={selectedLevels.length === levels.length}
                  indeterminate={
                    selectedLevels.length > 0 &&
                    selectedLevels.length < levels.length
                  }
                />
                <ListItemText primary={t("add.all-levels")} />
              </MenuItem>
              {label === "Manager"
                ? levels.map((level: any) => (
                    <MenuItem key={level.name} value={level.name}>
                      <Checkbox
                        checked={selectedLevels.some(
                          (lvl: any) => lvl.name === level.name,
                        )}
                      />
                      <ListItemText primary={level.name} />
                    </MenuItem>
                  ))
                : availableLevelsForHR.map((level: any) => (
                    <MenuItem key={level.name} value={level.name}>
                      <Checkbox
                        checked={selectedLevels.some(
                          (lvl: any) => lvl.name === level.name,
                        )}
                      />
                      <ListItemText primary={level.name} />
                    </MenuItem>
                  ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {/* Options and input */}
      <Grid item xs={12} sm={12} container spacing={2}>
        <Grid item xs={12} sm={12} container spacing={2} paddingBottom={2}>
          <Grid item xs={12} sm={6}>
            <Box
              border={1}
              borderRadius={0}
              paddingLeft={2}
              borderColor="rgba(255, 255, 255, 0.23)"
            >
              <FormControl component="fieldset" disabled={!enabled}>
                <RadioGroup
                  aria-label={`${label}-options-all`}
                  name={`${label}-options-all`}
                  value={selectedOption}
                  onChange={(e) => {
                    setInputForm({
                      ...inputForm,
                      [col.accessorKey]: {
                        ...sectionState,
                        selectedOption: e.target.value,
                        selectedGroups: [],
                        all_users: true,
                      },
                    });
                  }}
                >
                  <FormControlLabel
                    value="tutti"
                    control={<Radio />}
                    label={t("add.all-selected", { role: label })}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Box
              border={1}
              borderRadius={0}
              paddingLeft={2}
              borderColor="rgba(255, 255, 255, 0.23)"
            >
              <FormControl component="fieldset" disabled={!enabled}>
                <RadioGroup
                  aria-label={`${label}-options-upload`}
                  name={`${label}-options-upload`}
                  value={selectedOption}
                  onChange={(e) => {
                    setInputForm({
                      ...inputForm,
                      [col.accessorKey]: {
                        ...sectionState,
                        selectedOption: e.target.value,
                        selectedGroups: [],
                        all_users: false,
                      },
                    });
                  }}
                >
                  <FormControlLabel
                    value="carica"
                    control={<Radio />}
                    label={`${t("add.selected-choise")} ${label}`}
                  />
                </RadioGroup>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      {/* Select for groups */}
      <Grid container justifyContent="flex-end" spacing={2}>
        {col.sectionData.groups && (
          <Grid item xs={12} sm={6} paddingBottom={2}>
            <FormControl fullWidth>
              <Select
                labelId={`${label}-groups-label`}
                multiple
                value={selectedGroups}
                onChange={(e) => {
                  const selectedValues = e.target.value as any[];
                  setInputForm({
                    ...inputForm,
                    role: label,
                    [col.accessorKey]: {
                      ...sectionState,
                      selectedGroups: selectedValues,
                    },
                  });
                }}
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <Typography>{t("add.selected-group")}</Typography>;
                  }
                  return selected.map((group) => group.name).join(", ");
                }}
                displayEmpty
                disabled={selectedOption !== "carica"}
              >
                <MenuItem disabled value="">
                  {t("add.selected-group")}
                </MenuItem>
                {groups.map((group: any) => (
                  <MenuItem key={group.id} value={group}>
                    <Checkbox
                      checked={selectedGroups.some(
                        (selectedGroup) => selectedGroup.id === group.id,
                      )}
                    />
                    <ListItemText primary={group.name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

export default SectionFormNewCampaign;
