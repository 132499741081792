import React, { useState } from "react";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImageCst from "@components/BackgroundImageCst";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import LibraryListBox from "@components/LibraryListBoxCst";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import { StatusEnum } from "@components/ModalConfirmsCst/types";
import { useDeleteDetail } from "@hooks/useFetchDetail";
import { useParams } from "react-router-dom";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";

const MediaLibrary: React.FC = () => {
  const { t } = useTranslation(["media-library-page", "modal"]);
  const { id = "1" } = useParams();

  useResetFilters();

  const { dataTable, actionForRow, handleFetchData, mutate } = useFetchTable(
    ServicesURL.library + `?corporateId=${id}&generateUrl=true`,
    PagesID["corporate.library"],
  );

  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const [open, setOpen] = useState(false);

  const inputs = [
    {
      accessorKey: "title",
      header: "headerTitle",
      type: "text",
      permission: "text",
      required: false,
      id: "name",
    },
    {
      accessorKey: "search",
      header: "headerSearch",
      type: "text",
      permission: "search",
      required: false,
      id: "title",
      tooltip: "headerSearchTooltip",
      textTooltip: "headerSearchTextTooltip",
    },
    {
      accessorKey: "type",
      header: "headerType",
      type: "select-checkbox",
      service: "",
      required: false,
      id: "select",
      optionsKey: "mediaType",
    },
    {
      accessorKey: "learning_resource_type",
      header: "headerLearning_resource_type",
      type: "select",
      service: "",
      required: false,
      id: "select-tipology",
      optionsKey: "trainingFileType",
    },
    {
      accessorKey: "name",
      header: "headerName",
      type: "text",
      required: false,
      id: "file-name",
    },
    {
      accessorKey: "date",
      header: "headerDate",
      type: "dateTime",
      required: false,
      id: "lastModifiedDate",
    },
  ];

  const { trigger: triggerDeleteMedia, setId: setDeleteMediaId } =
    useDeleteDetail(ServicesURL.library);

  const handleDelete = React.useCallback(async () => {
    try {
      await triggerDeleteMedia();
      setOpenToast({ s: StatusEnum.OK, message: "" });
    } catch (error) {
      setOpen(false);
      setOpenToast(undefined);
      console.error(error);
    }
  }, [triggerDeleteMedia]);

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"600px"}
        image={"section-library.jpg"}
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <LibraryListBox
          idLibrary={PagesID.library}
          actionPermissions={actionForRow}
          filterList={inputs}
          fileList={dataTable}
          paginationDefault={16}
          onChangeFilterForm={handleFetchData}
          handleDelete={(id: any) => {
            setOpen(true);
            setDeleteMediaId(id);
          }}
        />
      </Container>
      <ModalConfirmsCst
        open={!!open}
        title={t("delete-item", { ns: "modal" })}
        description={t("confirm-delete", { ns: "modal" })}
        routeToSamePage={true}
        onCallBackClosed={() => {
          setOpen(false);
          setOpenToast(undefined);
          mutate();
        }}
        onActionConfirmed={() => {
          handleDelete();
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default MediaLibrary;
