import React, { useEffect, useState } from "react";
import { Box, Checkbox, Container, FormControlLabel } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";
import ButtonBackPage from "../../../../../../components/ButtonBackPage";
import { TableTabs } from "../../../../../../components/TableTabs/Index";
import CustomRowAction from "../../../../../../components/Table/components/CustomRowAction";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { useNavigate, useParams } from "react-router-dom";
import ButtonCst from "../../../../../../components/ButtonCst";
import { ServicesURL } from "../../../../../../utils/utilsApi";
import { useFetchTable } from "../../../../../../hooks/useFetchDataTable";
import { StatusEnum } from "../../../../../../components/ModalConfirmsCst/types";
import useSWRMutation from "swr/mutation";
import { axiosInstance, fetcher } from "../../../../../../services/config";
import { MethodType } from "../../../../../../services/type";
import ModalCst from "../../../../../../components/ModalCst";
import { useAuth } from "../../../../../../context/auth";
import ModalConfirmsCst from "../../../../../../components/ModalConfirmsCst";
import { RolesDigitedEnum } from "../../../../../../context/auth/types";

const GapManagementLearner: React.FC = () => {
  const { t } = useTranslation(["training-needs-details-user"]);
  const { t: tModal } = useTranslation(["modal"]);
  const [checkBoxData, setCheckBoxData] = useState<any>([]);
  const [checkBoxDataDelete, setCheckBoxDataDelete] = useState<any>([]);
  const [confirmModal, setConfirmModal] = useState<{
    s: StatusEnum;
    message: string;
    description?: string;
  }>();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openConfirmModal3, setOpenConfirmModal3] = useState(false);
  const [openModalGap, setOpenModalGap] = useState(false);
  const [goToFinalTab, setGoToFinalTab] = useState<any>(false);
  const [modalToast, setModalToast] = useState<any>(false);
  const [openToast, setOpenToast] = useState<{
    s: StatusEnum;
    message: string;
  }>();
  const [modalConfirm, setModalConfirm] = useState(false);
  const [actionData, setActionData] = useState<any>({});

  const [selectedTab, setSelectedTab] = useState(0);
  const { user } = useAuth();

  const { id, idCampaign } = useParams();

  const isLearnerState =
    window.location.href.includes("gap-management-learner") ||
    window.location.href.includes("gap-management-learner");

  const isCompletationFlux = window.location.href.includes(
    "completation-campaign",
  );

  const dataUser: any = useFetchTable(
    `${ServicesURL.digitedRoleUser}`
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{userId}", `${user && user?.id_user}`),
    PagesID["corporate.campaign"],
  );

  const isManager =
    dataUser &&
    dataUser &&
    dataUser.dataTable &&
    dataUser.dataTable.role == "MANAGER";

  const isHR =
    dataUser && dataUser.dataTable && dataUser.dataTable.role == "HR";

  const isAdmin =
    dataUser &&
    dataUser.dataTable &&
    !dataUser.dataTable.role &&
    user &&
    [
      RolesDigitedEnum.BUSINESS_ADMIN,
      RolesDigitedEnum.BUSINESS_ADMINISTRATOR,
      RolesDigitedEnum.PRODUCT_OWNER,
    ].some((role) => user.roles.includes(role));

  const isLearner =
    dataUser && dataUser.dataTable && dataUser.dataTable.role == "LEARNER";

  useEffect(() => {
    if (actionData.type == "reminder") setModalConfirm(true);
  }, [actionData]);

  useResetFilters();

  const navigate = useNavigate();

  const detailCorporate = useFetchTable(
    `${ServicesURL.digitedTipologyCampaign}`
      .replace("{campaignType}", "LEARNER")
      .replace("{corporateId}", `${id}`),
    PagesID["corporate.campaign"],
  );

  const { trigger: triggerConfirmGap } = useSWRMutation(
    `${ServicesURL.digitedDeleteDetailCampaign}`,
    (url: string, { arg }) => {
      return fetcher<any>(
        `${url.replace("{campaignId}", `${idCampaign}`)}`,
        MethodType.PUT,
        arg,
      );
    },
  );

  const detailCampaignLearner = useFetchTable(
    `${ServicesURL.digitedUsersCampaign}`.replace(
      "{campaignId}",
      `${idCampaign}`,
    ) +
      `${user && isCompletationFlux ? `?manager=${user.external_id}` : ""}` +
      `${user && isCompletationFlux ? "&" : "?"}role=LEARNER`,
    PagesID[
      isManager
        ? "corporate.learner.campaign.gap.manager.management"
        : "corporate.learner.campaign.gap.management"
    ],
  );
  const detailCampaignManager = useFetchTable(
    isAdmin || isHR
      ? `${ServicesURL.digitedUsersCampaign}`.replace(
          "{campaignId}",
          `${idCampaign}`,
        ) +
          `${user && isCompletationFlux ? `?manager=${user.external_id}` : ""}` +
          `${user && isCompletationFlux ? "&" : "?"}role=MANAGER`
      : null,
    PagesID["corporate.campaign.gap.management.tab.manager"],
  );
  const detailCampaignHR = useFetchTable(
    isHR || isAdmin
      ? `${ServicesURL.digitedUsersCampaign}`.replace(
          "{campaignId}",
          `${idCampaign}`,
        ) + `?role=HR`
      : null,
    PagesID["corporate.campaign.gap.management.tab.hr"],
  );
  const { trigger: reminderUser } = useSWRMutation(
    ServicesURL.reminderUsersCampaign,
    (url: string, { arg }: { arg: any }) => {
      const fullUrl = `${url}?corporate_id=${id}&campaign_id=${arg.campaign_id}&user_id=${arg.user_id}`;
      return axiosInstance.post(fullUrl);
    },
  );

  const dataSelected =
    selectedTab == 0
      ? detailCampaignLearner
      : selectedTab == 1
        ? detailCampaignManager
        : detailCampaignHR;

  const handleConfirmGap = async (row: any, complete: string) => {
    setOpenModalGap(true);
    setConfirmModal({ s: StatusEnum.LOADING, message: "loading" });

    const listIdUser = checkBoxData.map((el: any) => el.id);
    const listIdUserDeleted = checkBoxDataDelete.map((el: any) => el.id);

    const payloadConfirmGap = {
      is_closed: complete == "complete" ? true : false,
      campaign_user_list: {
        campaign_user_ids_to_confirm: listIdUser,
        campaign_user_ids_to_delete: listIdUserDeleted,
      },
    };

    try {
      setOpenConfirmModal(false);
      //@ts-ignore
      await triggerConfirmGap(payloadConfirmGap)
        .then((resp) => {
          if (resp && resp.output && resp.output) {
            setConfirmModal({
              s: StatusEnum.OK,
              message: "",
            });
            dataSelected.mutate({
              ...dataSelected.dataTable,
              output: resp.output,
            });
          }
        })
        .catch((err) => {
          setConfirmModal({
            s: StatusEnum.KO,
            message: "",
          });
          setOpenConfirmModal(false);
          setOpenConfirmModal3(false);
          console.error(err);
        });

      setOpenConfirmModal3(false);

      if (complete == "complete") {
        navigate(-1);
      }
    } catch (error) {
      setConfirmModal({
        s: StatusEnum.KO,
        message: "",
      });
      setOpenConfirmModal(false);
      setOpenConfirmModal3(false);
      console.error(error);
    }
  };

  const handleCheckBox = (row: any, type: string) => {
    let dataBox = type === "checkbox" ? checkBoxData : checkBoxDataDelete;
    let setData = type === "checkbox" ? setCheckBoxData : setCheckBoxDataDelete;
    if (dataBox && dataBox.some((el: any) => el.id === row.id)) {
      const removeData = dataBox.filter((el: any) => el.id !== row.id);
      setData(removeData);
    } else {
      setData([...dataBox, row]);
    }
  };

  const handleReminderUser = async () => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" });
    if (actionData) {
      setModalToast(true);
      try {
        await reminderUser({
          user_id: actionData.user_id,
          campaign_id: actionData.campaign_id,
        }).then((resp) => {
          if (resp && resp.status == 204) {
            return setOpenToast({
              s: StatusEnum.OK,
              message: tModal("successfully-reminder-user"),
            });
          } else {
            setModalConfirm(false);
            setModalToast(false);
            setOpenToast({ s: StatusEnum.KO, message: "No User Find" });
          }
        });
      } catch (error) {
        setModalConfirm(false);
        setModalToast(false);
        setOpenToast(undefined);
      }
    } else {
      setOpenToast({ s: StatusEnum.KO, message: "No User Find" });
    }
  };

  const handleAllCheckBox = (data: any, type: string) => {
    let dataBox = type === "checkbox" ? checkBoxData : checkBoxDataDelete;
    let setData = type === "checkbox" ? setCheckBoxData : setCheckBoxDataDelete;

    const dataBoxFiltered = dataBox.filter(
      (el: any) => el.status != "COMPLETED",
    );

    const dataFiltered = data.dataTable.output.filter(
      (el: any) => el.status != "COMPLETED",
    );

    const differentDataBox =
      type === "checkbox" ? checkBoxDataDelete : checkBoxData;
    const updateData = dataFiltered.filter(
      (el: any) => !differentDataBox.some((user: any) => user.id === el.id),
    );

    if (dataBox && dataFiltered && dataBoxFiltered.length < updateData.length) {
      return setData(updateData);
    }

    return setData([]);
  };

  const configurationActionForRow = React.useMemo(() => {
    return (row: any) => {
      let completedUser = row.original.confirmed == true;
      const isCompletedStatus =
        row.original &&
        row.original.status &&
        row.original.status == "COMPLETED";
      return dataSelected.actionForRow
        ?.filter((el: any) => {
          if (completedUser) return el.type != "checkbox";
          return el;
        })
        .map((action: any) => {
          switch (action.type) {
            case "visibility": {
              if (completedUser) {
                return {
                  ...action,
                  url: "detail-user-skills",
                  useId: row.original.id,
                };
              } else {
                return {
                  ...action,
                  useId: row.original.id,
                };
              }
            }
            case "reminder": {
              if (isCompletedStatus) {
                return {
                  ...action,
                  disabled: true,
                };
              } else {
                return {
                  ...action,
                  disabled: false,
                };
              }
            }
            case "checkbox": {
              const isExist = checkBoxData.some(
                (el: any) => el.id == row.original.id,
              );
              const isExistDelete = checkBoxDataDelete.some(
                (el: any) => el.id == row.original.id,
              );

              return {
                ...action,
                disabled: isExist
                  ? "confirmed"
                  : isExistDelete
                    ? "delete"
                    : null,
              };
            }
            case "reminder": {
              let disabled =
                selectedTab == 1
                  ? row.original.hr_state == "COMPLETED" ||
                    row.original.campaign_state == "BLOCKED"
                  : row.original.campaign_state == "COMPLETED";

              return {
                ...action,
                disabled: disabled,
              };
            }

            default:
              return action;
          }
        });
    };
  }, [dataSelected.actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew = configurationActionForRow(row) && [
      ...configurationActionForRow(row),
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        setActionData={setActionData}
        configurationActionForRow={configRowNew}
        setCheckBoxData={handleCheckBox}
        checkBoxData={[checkBoxData, checkBoxDataDelete]}
      />,
    ];
  };

  const rowData =
    detailCorporate &&
    detailCorporate.dataTable &&
    detailCorporate.dataTable.output &&
    detailCorporate.dataTable.output.find(
      (el: any) => el.campaign_id == idCampaign,
    );

  const dataKnowledge =
    dataSelected &&
    dataSelected.columns &&
    dataSelected.columns.filter((column) => column.accessorKey != "capacity");
  const dataCapacity =
    dataSelected &&
    dataSelected.columns &&
    dataSelected.columns.filter(
      (column) =>
        column.accessorKey !== "knowledge_area" &&
        column.accessorKey !== "knowledge_domain",
    );

  const isCompletedState =
    rowData && rowData.hr_state.toLowerCase() == "completed";

  const tabs = [
    {
      [t("knowledge")]: dataSelected && (
        <Table
          id={PagesID["corporate.campaign.gap.management"]}
          data={dataSelected.dataTable?.output}
          columns={dataSelected.columns || []}
          onChangeFilterForm={dataSelected.handleFetchData}
          renderRowActions={customRenderRowActions}
          configurationTopToolbar={dataSelected.actionsTopToolbar}
          totalPages={dataSelected.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          renderActionIcon={
            <>
              <FormControlLabel
                control={
                  <CheckBoxIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label="Tutti"
              />
              <FormControlLabel
                control={
                  <DeleteIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label="Tutti"
              />
            </>
          }
          renderActionIconBottomToolbar={
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxData.length}
                    checkedIcon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox(dataSelected, "checkbox")}
                  />
                }
                label=""
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxDataDelete.length}
                    checkedIcon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox(dataSelected, "delete")}
                  />
                }
                label=""
              />
            </>
          }
          renderActionUnderBottomToolbar={
            <ButtonCst
              onClick={() => setGoToFinalTab(true)}
              sx={{ cursor: "pointer" }}
              disabled={!checkBoxData.length && !checkBoxDataDelete.length}
              variant="contained"
              id={"continueButton"}
            >
              {tModal("confirm-gap-modal")}
            </ButtonCst>
          }
        />
      ),
    },
    {
      [t("capacity")]: dataSelected && (
        <Table
          id={PagesID["corporate.campaign.gap.management.capacity"]}
          data={dataSelected.dataTable?.output}
          columns={dataCapacity || []}
          onChangeFilterForm={dataSelected.handleFetchData}
          renderRowActions={customRenderRowActions}
          configurationTopToolbar={dataSelected.actionsTopToolbar}
          totalPages={dataSelected.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          renderActionIcon={
            <>
              <FormControlLabel
                control={
                  <CheckBoxIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label="Tutti"
              />
              <FormControlLabel
                control={
                  <DeleteIcon
                    sx={{
                      fill: "#84293c !important",
                    }}
                  />
                }
                label="Tutti"
              />
            </>
          }
          renderActionIconBottomToolbar={
            <>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxData.length}
                    checkedIcon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <CheckBoxIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => setOpenConfirmModal(true)}
                  />
                }
                label=""
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checkBoxDataDelete.length}
                    checkedIcon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#f43e63 !important",
                        }}
                      />
                    }
                    icon={
                      <DeleteIcon
                        fontSize="large"
                        sx={{
                          fill: "#84293c !important",
                        }}
                      />
                    }
                    onChange={() => handleAllCheckBox(dataSelected, "delete")}
                  />
                }
                label=""
              />
            </>
          }
          renderActionUnderBottomToolbar={
            <ButtonCst
              onClick={() => setOpenConfirmModal(true)}
              sx={{ cursor: "pointer" }}
              disabled={!checkBoxData.length && !checkBoxDataDelete.length}
              variant="contained"
              id={"buttonConfirm"}
            >
              {tModal("confirm-gap-modal")}
            </ButtonCst>
          }
        />
      ),
    },
  ];

  const tabsRole = [
    {
      ...(!isLearner && {
        Learner: (
          <Table
            id={PagesID["corporate.campaign.gap.management"]}
            data={dataSelected?.dataTable?.output}
            columns={dataKnowledge || []}
            onChangeFilterForm={dataSelected.handleFetchData}
            renderRowActions={customRenderRowActions}
            configurationTopToolbar={dataSelected.actionsTopToolbar}
            totalPages={dataSelected.dataTable?.metadata?.total_elements}
            modalTitle={t("hero-title")?.replace("-", "") || ""}
            renderActionIcon={
              <>
                {
                  // detailCampaignLearner.dataTable && detailCampaignLearner.dataTable.output && detailCampaignLearner.dataTable.output.some((el:any) => el.confirmed != true) &&
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "3px",
                      padding: "0 20px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <CheckBoxIcon
                          sx={{
                            fill: "#84293c !important",
                          }}
                        />
                      }
                      label="Tutti"
                    />
                    <FormControlLabel
                      control={
                        <DeleteIcon
                          sx={{
                            fill: "#84293c !important",
                          }}
                        />
                      }
                      label="Tutti"
                    />
                  </div>
                }
              </>
            }
            renderActionIconBottomToolbar={
              <>
                {
                  //  detailCampaignLearner.dataTable && detailCampaignLearner.dataTable.output && detailCampaignLearner.dataTable.output.some((el:any) => el.confirmed != true) &&
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "8px",
                      padding: "0 6px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkBoxData.length}
                          checkedIcon={
                            <CheckBoxIcon
                              fontSize="large"
                              sx={{
                                fill: "#f43e63 !important",
                              }}
                            />
                          }
                          icon={
                            <CheckBoxIcon
                              fontSize="large"
                              sx={{
                                fill: "#84293c !important",
                              }}
                            />
                          }
                          onChange={() =>
                            handleAllCheckBox(detailCampaignLearner, "checkbox")
                          }
                        />
                      }
                      label=""
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkBoxDataDelete.length}
                          checkedIcon={
                            <DeleteIcon
                              fontSize="large"
                              sx={{
                                fill: "#f43e63 !important",
                              }}
                            />
                          }
                          icon={
                            <DeleteIcon
                              fontSize="large"
                              sx={{
                                fill: "#84293c !important",
                              }}
                            />
                          }
                          onChange={() =>
                            handleAllCheckBox(detailCampaignLearner, "delete")
                          }
                        />
                      }
                      label=""
                    />
                  </div>
                }
              </>
            }
            renderActionUnderBottomToolbar={
              <ButtonCst
                onClick={() => setOpenConfirmModal(true)}
                sx={{ cursor: "pointer" }}
                disabled={!checkBoxData.length && !checkBoxDataDelete.length}
                variant="contained"
                id={"continueButton"}
              >
                {tModal("confirm-gap-modal")}
              </ButtonCst>
            }
          />
        ),
      }),
    },
    {
      ...(!isManager &&
        !isLearner && {
          Manager: (
            <Table
              id={PagesID["corporate.campaign.gap.management.tab.manager"]}
              data={dataSelected?.dataTable?.output}
              columns={dataKnowledge || []}
              onChangeFilterForm={dataSelected.handleFetchData}
              renderRowActions={customRenderRowActions}
              configurationTopToolbar={dataSelected.actionsTopToolbar}
              totalPages={dataSelected.dataTable?.metadata?.total_elements}
              modalTitle={t("hero-title")?.replace("-", "") || ""}
            />
          ),
        }),
    },
    {
      ...(!isManager &&
        (isHR || isAdmin) &&
        !isCompletationFlux &&
        !isLearner && {
          HR: (
            <Table
              id={PagesID["corporate.campaign.gap.management.tab.hr"]}
              data={dataSelected?.dataTable?.output}
              columns={dataKnowledge || []}
              onChangeFilterForm={dataSelected.handleFetchData}
              renderRowActions={customRenderRowActions}
              configurationTopToolbar={dataSelected.actionsTopToolbar}
              totalPages={dataSelected.dataTable?.metadata?.total_elements}
              modalTitle={t("hero-title")?.replace("-", "") || ""}
            />
          ),
        }),
    },
  ];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={t("validation-campaign-learner")}
          description={t("hero-description")}
        />
        <ButtonBackPage goBack={2} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          {isLearnerState ? (
            <TableTabs
              setExternalSelectedTab={setSelectedTab}
              tabs={tabsRole}
            />
          ) : (
            <TableTabs
              tabs={isCompletedState ? tabs : tabs}
              goToFinalTab={goToFinalTab}
              setGoToFinalTab={setGoToFinalTab}
            />
          )}
        </Box>
        <ModalCst
          noToast
          open={openConfirmModal}
          setOpen={setOpenConfirmModal}
          title={tModal("confirm-gap-modal") || ""}
          description={tModal("confirm-gap-modal-description") || ""}
          typeOfModal={"confirm"}
          data={{
            callBackSubmit: async () => {
              if (isCompletationFlux)
                await handleConfirmGap(rowData, "stayActive");
              else setOpenConfirmModal3(true);
            },
          }}
        />
        <ModalCst
          noToast
          open={openConfirmModal3}
          setOpen={setOpenConfirmModal3}
          submitText={tModal("confirm-closed-campaign") || ""}
          cancelText={tModal("stay-active-campaign") || ""}
          title={tModal("closed-campaign") || ""}
          description={tModal("confirm-closed-campaign-description") || ""}
          typeOfModal={"confirm"}
          data={{
            onClose: async () => {
              await handleConfirmGap(rowData, "stayActive");
            },
            callBackSubmit: async () => {
              await handleConfirmGap(rowData, "complete");
            },
          }}
        />
        <ModalCst
          noToast
          open={modalConfirm}
          setOpen={setModalConfirm}
          title={t("modal-confirm-reminder") || ""}
          description={
            t("modal-confirm-reminder-user", {
              username: `${actionData.user_name} ${actionData.user_last_name}`,
            }) || ""
          }
          typeOfModal={"confirm"}
          submitText={"Conferma"}
          cancelText={"Annulla"}
          data={{
            onClose: () => setModalConfirm(false),
            callBackSubmit: async () => {
              await handleReminderUser();
            },
          }}
        />
        <ModalConfirmsCst
          removeButtons
          open={modalToast}
          routeToSamePage={true}
          title={""}
          description={""}
          onActionConfirmed={() => {}}
          onCallBackClosed={() => {
            setModalConfirm(false);
            setOpenToast(undefined);
            setModalToast(false);
          }}
          status={openToast}
        />

        <ModalConfirmsCst
          open={openModalGap}
          title={``}
          description={``}
          routeToSamePage={true}
          onCallBackClosed={() => {
            setOpenModalGap(false);
            setConfirmModal(undefined);
            setOpenConfirmModal(false);
          }}
          onActionConfirmed={async () => {}}
          status={confirmModal}
        />
      </Container>
    </AnimationFadeIn>
  );
};

export default GapManagementLearner;
