import { InputCst, MultiSelectCst, SelectCst } from "@components/InputCst";
import {
  Box,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
  useTheme,
} from "@mui/material";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useParams } from "react-router-dom";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { useNotificationState } from "@pages/Contents/Corporate/Notifications/context/NotificationContext";
import { Options } from "@components/InputCst/SelectCst/types";
import { ListNotificationType } from "../../../../../../interfaces/Common";
import {
  getFlagKey,
  mergeParameters,
} from "@pages/Contents/Corporate/Notifications/utils/utils";
import {
  Col,
  ResponseEvents,
  Step1Props,
  ValuesInterface,
} from "@pages/Contents/Corporate/Notifications/types";

const Step1: React.FC<Step1Props> = ({ row, columns, getValues, errors }) => {
  const theme = useTheme();
  const { t: notificationsTranslation } = useTranslation(["notification-page"]);
  const { id, idNotification } = useParams();
  const { state, dispatch } = useNotificationState();
  const [selectedEventType, setSelectedEventType] = React.useState<any>([]);

  const { dataTable: dataEvents } = useFetchTable(
    `${ServicesURL.getEvents}`,
    PagesID["corporate.notifications"],
  );

  const optionsEvents = useMemo(() => {
    if (!dataEvents) return;
    return dataEvents.map((el: ResponseEvents) => {
      return {
        value: el.name,
        ...el,
      };
    });
  }, [dataEvents]);

  const handleSwitchChange = (type: ListNotificationType | string) => {
    //ON SWITCH CHANGE
    const flagKey = getFlagKey(type);
    dispatch({
      type: "SET_INPUT_VALUES",
      payload: { key: flagKey, value: !state[flagKey] },
    }); //SET CATEGORY FLAG VALUE
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: theme.palette.background.paper,
          minHeight: "495px",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Grid container spacing={3}>
          {(columns as []).map((col: Col) => {
            if (col.type === "text") {
              return (
                <Grid key={col.accessorKey} item xs={12} sm={6}>
                  <InputCst
                    key={col.accessorKey}
                    id={col.accessorKey}
                    label={col.header}
                    value={state[col.accessorKey]}
                    required={col.required}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      dispatch({
                        type: "SET_INPUT_VALUES",
                        payload: {
                          key: col.accessorKey,
                          value: e.target.value,
                        },
                      });
                    }}
                    maxValue={col?.rules?.max?.value}
                    multiline={col.multiline}
                    rows={col.rows}
                    disabled={col.disabled}
                    textTooltip={col.tooltip}
                    type={col.inpuType || "text"}
                    error={!!errors[col.accessorKey]}
                    errorMessage={`${notificationsTranslation(`validation.${errors[col.accessorKey]}`)}`}
                  />
                </Grid>
              );
            }
            return null;
          })}
          <Grid key={"event"} item xs={12} sm={6}>
            <SelectCst
              key={"event"}
              id={"event"}
              label={notificationsTranslation("eventName") || ""}
              value={state["event"]}
              required={true}
              onChange={(e) => {
                const selected = optionsEvents.find(
                  (item: ValuesInterface) => item.value === e,
                );
                setSelectedEventType(selected);

                if (!selected) return;
                dispatch({
                  type: "SET_INPUT_VALUES",
                  payload: { key: "event", value: e },
                });
                dispatch({
                  type: "SET_INPUT_VALUES",
                  payload: {
                    key: "parameters",
                    value: [...selected.parameters],
                  },
                });
                dispatch({
                  type: "SET_INPUT_VALUES",
                  payload: {
                    key: "scheduleOptionsTypes",
                    value: selected.scheduleTypes,
                  },
                });
                dispatch({
                  type: "SET_INPUT_VALUES",
                  payload: {
                    key: "scheduleOptionsUnits",
                    value: selected.scheduleUnits,
                  },
                });
                dispatch({
                  type: "SET_INPUT_VALUES",
                  payload: {
                    key: "catalogueFilterEnabled",
                    value: selected.catalogueFilterEnabled,
                  },
                });
              }}
              options={optionsEvents as Options[]}
              disabled={!!idNotification}
              error={!!errors.event}
              errorMessage={`${notificationsTranslation(`validation.${errors.event}`)}`}
            />
          </Grid>
        </Grid>
        {!!state["event"] &&
          mergeParameters(
            selectedEventType.parameters || state.parameters,
            idNotification,
          ).length > 0 && (
            <Grid key={"parameters"} item>
              <Box
                sx={{
                  backgroundColor: theme.palette.background.paper,
                  padding: "16px",
                  marginY: "25px",
                  borderRadius: "3px",
                  border: `1px solid ${theme.palette.divider}`,
                }}
              >
                {mergeParameters(
                  selectedEventType.parameters || state.parameters,
                  idNotification,
                ).map((item: any) => {
                  const data = item.parameters ? item.parameters : item;
                  switch (data.type) {
                    case "BOOLEAN": {
                      const flagKey = getFlagKey(data.name.toLowerCase());
                      if (typeof state[flagKey] !== "boolean") {
                        dispatch({
                          type: "SET_INPUT_VALUES",
                          payload: {
                            key: flagKey,
                            value: data.value === "true",
                          },
                        });
                      }
                      return (
                        <>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={Boolean(state[flagKey])}
                                value={Boolean(state[flagKey])}
                                onChange={() => {
                                  handleSwitchChange(data.name.toLowerCase());
                                }}
                              />
                            }
                            sx={{
                              marginY: "16px",
                            }}
                            label={`${notificationsTranslation(data.name)}`}
                            labelPlacement="end"
                          />
                          <Divider />
                        </>
                      );
                    }
                    case "NULLABLE_INTEGER": {
                      const flagKey = getFlagKey(data.name.toLowerCase());
                      return (
                        <>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={Boolean(state[flagKey])}
                                value={Boolean(state[flagKey])}
                                onChange={() => {
                                  handleSwitchChange("enrolled_days_ago");
                                  dispatch({
                                    type: "SET_INPUT_VALUES",
                                    payload: {
                                      key: "enrolled_days_ago",
                                      value: null,
                                    },
                                  });
                                }}
                              />
                            }
                            sx={{
                              marginY: "16px",
                            }}
                            label={`${notificationsTranslation("ACTIVATE_ENROLLED_DAYS_AGO")}`}
                            labelPlacement="end"
                          />
                          <InputCst
                            key={data.name}
                            id={data.name}
                            label={`${notificationsTranslation(data.name)}`}
                            required={data.required}
                            value={
                              Boolean(state[flagKey])
                                ? state[data.name.toLowerCase()] || data.value
                                : ""
                            }
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              dispatch({
                                type: "SET_INPUT_VALUES",
                                payload: {
                                  key: data.name.toLowerCase(),
                                  value: e.target.value,
                                },
                              });
                            }}
                            sx={{
                              marginY: "16px",
                            }}
                            type="number"
                            max={data.maxValue || null}
                            min={data.minValue || null}
                            error={!!errors[data.name.toLowerCase()]}
                            disabled={
                              data.name === "ENROLLED_DAYS_AGO" &&
                              !state[flagKey]
                            }
                            errorMessage={`${notificationsTranslation(`validation.${errors[data.name.toLowerCase()]}`)}`}
                          />
                        </>
                      );
                    }
                    case "INTEGER": {
                      return (
                        <>
                          <InputCst
                            key={data.name}
                            id={data.name}
                            label={`${notificationsTranslation(data.name)}`}
                            required={data.required}
                            value={
                              state[data.name.toLowerCase()] ||
                              data.value ||
                              data.defaultValue
                            }
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>,
                            ) => {
                              dispatch({
                                type: "SET_INPUT_VALUES",
                                payload: {
                                  key: data.name.toLowerCase(),
                                  value: e.target.value,
                                },
                              });
                            }}
                            sx={{
                              marginY: "16px",
                            }}
                            type="number"
                            max={data.maxValue || null}
                            min={data.minValue || null}
                            error={!!errors[data.name.toLowerCase()]}
                            errorMessage={`${notificationsTranslation(`validation.${errors[data.name.toLowerCase()]}`)}`}
                          />
                        </>
                      );
                    }
                    case "MULTIPLE_CHOICE": {
                      return (
                        <>
                          <MultiSelectCst
                            id={data.name}
                            label={`${notificationsTranslation(data.name)}`}
                            value={
                              state[data.name.toLowerCase()] || data.values
                            }
                            onChange={(e) => {
                              const selectedValues = e.map(
                                (option: ValuesInterface) => option,
                              );
                              dispatch({
                                type: "SET_INPUT_VALUES",
                                payload: {
                                  key: data.name.toLowerCase(),
                                  value: selectedValues,
                                },
                              });
                            }}
                            sx={{
                              marginY: "16px",
                            }}
                            options={data.choices}
                          />
                        </>
                      );
                    }
                    default: {
                      return <></>;
                    }
                  }
                })}
              </Box>
            </Grid>
          )}
      </Box>
    </>
  );
};

export default Step1;
