//REACT AND REACT BASED LIBRARIES
import { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSWRMutation from "swr/mutation";
//COMPONENTS
import { Box, Container } from "@mui/material";
import AddEditFormCst from "@components/AddEditFormCst";
import AnimationFadeIn from "@components/AnimationFadeIn";
import BackgroundImageCst from "@components/BackgroundImageCst";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import ModalConfirmsCst from "@components/ModalConfirmsCst";
import {
  StatusEnum,
  StatusModalConfirmTypes,
} from "@components/ModalConfirmsCst/types";
import { ColumnInterface } from "@components/Table/types";
//CUSTOM HOOKS AND UTILS
import { useFetchDetail } from "@hooks/useFetchDetail";
import { ServicesURL, uploadFile } from "@utils/utilsApi";
import { useFetchMock } from "src/mock_data/useMockData";
import { MethodType } from "src/services/type";
import { PagesID } from "@utils/utilsConfigurations";
import { fetcher } from "src/services/config";
import { isTrueValue } from "@utils/utilsValidators";
import { useFetchTable } from "@hooks/useFetchDataTable";
import { formatDateWithMinutes } from "@utils/utilsDate";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const Enrollments: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["enrollment-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE
  const { t: tModal } = useTranslation(["modal"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  //?? HOOK PER PARAMETRI DA URL (IL NOME è LO STESSO DI QUELLO INSERITO IN ROUTER es. /:idLO)
  const {
    id,
    idLO,
    idLORow,
    userDomainId,
    userId,
    groupId,
    groupType,
    idEditionLOActivity,
  } = useParams();

  //?? HOOK PER NAVIGAER IN ALTRE PAGINE
  const navigate = useNavigate();
  const location = useLocation();

  //!! STATO PER GESTIONE MODALE DI CONFERMA/RISPOSTA/ERRORE
  const [openToast, setOpenToast] = useState<StatusModalConfirmTypes>();

  const getQueryParam = () => {
    if (userDomainId) {
      return `&domainId=${userDomainId}`;
    } else if (userId) {
      return `&userId=${userId}`;
    } else if (groupId) {
      return `&massiveGroupId=${groupId}&groupType=${groupType}`;
    } else {
      return "";
    }
  };

  const { dataDetail: getCourse } = useFetchDetail(
    `${ServicesURL["learning-catalogue"]}/${id}/learning-objects/courses`,
    idLORow || idLO || "",
    PagesID["corporate.catalog.courses"],
    { onError: () => null },
  );

  const { columns, dataTable, mutate } = useFetchTable(
    location?.pathname.includes("subscribed") ||
      (location?.pathname.includes("groups") &&
        location?.pathname.includes("learning-objects"))
      ? `${ServicesURL.getEnrollment}?corporateId=${id}${
          idLORow || idLO
            ? `&objectId=${idLORow || idLO}`
            : idEditionLOActivity
              ? `&objectId=${idEditionLOActivity}`
              : ``
        }${getQueryParam()}`
      : null,
    PagesID["enrollments"],
  );

  const { dataDetail: dataDetailActivity } = useFetchDetail(
    `${ServicesURL.getActivities.replace(":idCorporate", id || "")}`,
    idLORow || idLO,
    PagesID["corporate.catalog.activities"],
    { onError: () => null },
  );

  // const { trigger } = useSWRMutation(`${ServicesURL.getTerms}`, (url: string, arg: { arg: any }) => {
  //     return fetcher<any>(`${url}`, MethodType.POST, { ...arg.arg });
  // });

  const { trigger: triggerPOST } = useSWRMutation(
    `${ServicesURL.postEnrollment}/`,
    (
      url: string,
      {
        arg,
      }: {
        arg: any;
      },
    ) => {
      return fetcher<any>(`${url}`, MethodType.POST, arg);
    },
  );

  const { trigger: triggerPOSTMassive } = useSWRMutation(
    `${ServicesURL.postEnrollmentMassive}/`,
    (
      url: string,
      {
        arg,
      }: {
        arg: any;
      },
    ) => {
      return fetcher<any>(`${url}`, MethodType.POST, arg);
    },
  );

  const { trigger: triggerGroupPOSTMassive } = useSWRMutation(
    `${ServicesURL.postGroupEnrollmentMassive}/`,
    (
      url: string,
      {
        arg,
      }: {
        arg: any;
      },
    ) => {
      return fetcher<any>(`${url}`, MethodType.POST, arg);
    },
  );

  //!! FUNZIONE PER GESTIRE ONSUBMIT DELLA FORM
  const onSubmit = async (inputForm: any) => {
    setOpenToast({ s: StatusEnum.LOADING, message: "loading" }); //!!
    let objToSend = [
      {
        isAdmin: true,
        enrollDate: `${formatDateWithMinutes(new Date())}`,
        enrollType: inputForm.enrollType,
        domainId: (userDomainId && +userDomainId) || null,
        forcedExpiration:
          inputForm.forcedExpiration !== null
            ? isTrueValue(inputForm.forcedExpiration)
            : null,
        learningObject: {
          corporateId: (id && +id) || null,
          objectId:
            (idEditionLOActivity
              ? +idEditionLOActivity
              : (idLORow && +idLORow) || (idLO && +idLO) || null) || null,
          objectType: dataTable?.enrollmentResponses[0]?.objectType,
        },
        userId: Number(userId) || null,
        daysToComplete: +inputForm.daysToComplete || null,
        expirationDate: inputForm.expirationDate
          ? formatDateWithMinutes(new Date(inputForm.expirationDate)).split(
              " ",
            )[0]
          : null,
        isExpirationFixed: isTrueValue(inputForm.expiration_date_flag)
          ? !isTrueValue(inputForm.isExpirationFixed)
          : null,
        isExpirationHard:
          inputForm.isExpirationHard !== null
            ? isTrueValue(inputForm.isExpirationHard)
            : null,
        massiveDomainId: (userDomainId && +userDomainId) || null,
        massiveGroupId: (groupId && +groupId) || null,
      },
    ];

    let objToSendMassive = {
      isAdmin: true,
      enrollDate: `${formatDateWithMinutes(new Date())}`,
      enrollType: inputForm.enrollType,
      domainId: (userDomainId && +userDomainId) || null,
      forcedExpiration:
        inputForm.forcedExpiration !== null
          ? isTrueValue(inputForm.forcedExpiration)
          : null,
      groupType: groupType,
      learningObject: {
        corporateId: (id && +id) || null,
        objectId:
          (idEditionLOActivity
            ? +idEditionLOActivity
            : (idLORow && +idLORow) || (idLO && +idLO) || null) || null,
      },
      daysToComplete: +inputForm.daysToComplete || null,
      expirationDate: inputForm.expirationDate
        ? formatDateWithMinutes(new Date(inputForm.expirationDate)).split(
            " ",
          )[0]
        : null,
      isExpirationFixed: isTrueValue(inputForm.expiration_date_flag)
        ? !isTrueValue(inputForm.isExpirationFixed)
        : null,
      isExpirationHard:
        inputForm.isExpirationHard !== null
          ? isTrueValue(inputForm.isExpirationHard)
          : null,
      massiveListDomainId: (userDomainId && [+userDomainId]) || null,
      massiveListGroupId: (groupId && [+groupId]) || null,
    };

    let objToSendMassiveGroup = {
      isAdmin: true,
      enrollDate: `${formatDateWithMinutes(new Date())}`,
      enrollType: inputForm.enrollType,
      domainId: (userDomainId && +userDomainId) || null,
      forcedExpiration:
        inputForm.forcedExpiration !== null
          ? isTrueValue(inputForm.forcedExpiration)
          : null,
      groupType: groupType,
      learningObject: {
        corporateId: (id && +id) || null,
        forcedEnroll: true,
        objectId:
          (idEditionLOActivity
            ? [+idEditionLOActivity]
            : (idLORow && [+idLORow]) || (idLO && [+idLO]) || null) || null,
      },
      daysToComplete: +inputForm.daysToComplete || null,
      expirationDate: inputForm.expirationDate
        ? formatDateWithMinutes(new Date(inputForm.expirationDate)).split(
            " ",
          )[0]
        : null,
      isExpirationFixed: isTrueValue(inputForm.expiration_date_flag)
        ? !isTrueValue(inputForm.isExpirationFixed)
        : null,
      isExpirationHard:
        inputForm.isExpirationHard !== null
          ? isTrueValue(inputForm.isExpirationHard)
          : null,
      massiveListDomainId: (userDomainId && [+userDomainId]) || null,
      massiveListGroupId: (groupId && [+groupId]) || null,
    };

    try {
      if (groupId || userDomainId) {
        let userSubscribed = null;
        let response = groupId
          ? await triggerGroupPOSTMassive(objToSendMassiveGroup)
          : await triggerPOSTMassive(objToSendMassive);
        userSubscribed = response?.totalDistinctPart;
        setOpenToast({
          s: StatusEnum.OK,
          title: `${tModal("enrollment.taken-charge")}`,
          successMessage: `${tModal(
            "enrollment.users-added",
          )} ${userSubscribed || 3}`,
        }); //!!
      } else {
        const response = await triggerPOST(objToSend);
        if (response?.errorMessage) {
          setOpenToast({
            s: StatusEnum.KO,
            message: response?.errorMessage || undefined,
            title: "",
          }); //!!
        } else {
          setOpenToast({ s: StatusEnum.OK, message: "" }); //!!
        }
      }
    } catch (error) {
      setOpenToast(undefined); //!!
      console.error(error); //!!
    }
    mutate && mutate();
  };

  const inputsException = (col: any, inputForm: any) => {
    let expirationToDisable = [
      "isExpirationFixed",
      "expirationDate",
      "isExpirationHard",
      "daysToComplete",
    ];

    if (col.accessorKey === "forcedExpiration") {
      if (
        inputForm.enrollType === "TOP" ||
        inputForm.enrollType === "TOP_MANDATORY"
      ) {
        if (
          inputForm?.isExpirationFixed &&
          !isTrueValue(inputForm?.isExpirationFixed)
        ) {
          col.disabled = false;
          col.required = true;
        } else {
          col.disabled = true;
          col.required = false;
          if (Object.keys(inputForm).length > 0) {
            inputForm.forcedExpiration = false;
          }
        }
      } else {
        col.disabled = true;
        col.required = false;
        if (Object.keys(inputForm).length > 0) {
          inputForm.forcedExpiration = false;
        }
      }
    }

    if (
      isTrueValue(inputForm["expiration_date_flag"]) &&
      col.accessorKey === "expiration_date_flag"
    ) {
      col.callBack = (e: any, row: any) => {
        return {
          ...inputForm,
          expiration_date_flag: e,
          isExpirationFixed: undefined,
          expirationDate: undefined,
          isExpirationHard: undefined,
          daysToComplete: undefined,
        };
      };
    }

    if (col.accessorKey === "isExpirationFixed") {
      col.callBack = (e: any, row: any) => {
        return {
          ...inputForm,
          isExpirationFixed: e,
          expirationDate: undefined,
          daysToComplete: undefined,
        };
      };
    }

    if (
      inputForm?.enrollType &&
      inputForm?.enrollType !== "TOP" &&
      col.accessorKey === "enrollType"
    ) {
      col.callBack = (e: any, row: any) => {
        return { ...inputForm, enrollType: e };
      };
    }

    if (expirationToDisable.includes(col.accessorKey)) {
      if (
        [undefined, "false", false].includes(inputForm["expiration_date_flag"])
      ) {
        col.required = false;
        col.disabled = true;
      } else {
        col.required = true;
        col.disabled = false;
      }
    }

    if (col.accessorKey === "expirationDate") {
      if (
        inputForm["isExpirationFixed"] === undefined ||
        isTrueValue(inputForm["isExpirationFixed"])
      ) {
        col.disabled = true;
        col.required = false;
      } else if (
        [undefined, "false", null, false].includes(
          inputForm["isExpirationFixed"],
        )
      ) {
        col.disabled = false;
        col.required = true;
      }
    }

    if (col.accessorKey === "daysToComplete") {
      if (
        inputForm["isExpirationFixed"] === undefined ||
        !isTrueValue(inputForm["isExpirationFixed"])
      ) {
        col.disabled = true;
        col.required = false;
      } else {
        col.disabled = false;
        col.required = true;
      }
    }

    if (col.accessorKey === "enrollType") {
      if (getCourse && !!getCourse?.toj_specialization) {
        col.optionsToDisable = ["AUTO", "BOTTOM", "PRE_AUTO", "PRE_BOTTOM"];
      } else {
        let optionsToDisable = optionBasedOnPriority();
        if (optionsToDisable) {
          optionsToDisable = [...optionsToDisable, "AUTO", "BOTTOM"];
        } else {
          optionsToDisable = ["AUTO", "BOTTOM"];
        }
        if (dataDetailActivity)
          if (
            ["DA_PHYSICAL_CLASS", "DA_VIRTUAL_CLASS"].includes(
              dataDetailActivity.type,
            )
          ) {
            optionsToDisable = [...optionsToDisable];
          }
        col.optionsToDisable = optionsToDisable;
      }
    }

    return col;
  };

  const optionBasedOnPriority: any = () => {
    let priority: any = {
      TOP_MANDATORY: 0,
      TOP: 1,
      AUTO: 2,
      BOTTOM: 3,
      PRE_AUTO: 4,
      PRE_BOTTOM: 5,
    };
    if (dataTable?.enrollmentResponses) {
      if (dataTable?.enrollmentResponses[0]?.enrollType) {
        return Object.keys(priority).filter((el: any, index: any) => {
          return (
            priority[dataTable?.enrollmentResponses[0]?.enrollType] <=
            priority[el]
          );
        });
      } else {
        return undefined;
      }
    } else {
      return undefined;
    }
  };

  const getExpirationValue = () => {
    const isNotSet =
      dataTable?.enrollmentResponses[0]?.isExpirationFixed === true &&
      dataTable?.enrollmentResponses[0]?.daysToComplete === null &&
      dataTable?.enrollmentResponses[0]?.expirationDate === "9999-12-31" &&
      dataTable?.enrollmentResponses[0]?.isExpirationHard === false;

    if (!dataTable?.enrollmentResponses[0]) {
      return {};
    }

    if (!!isNotSet) {
      const newObject = {
        ...dataTable.enrollmentResponses[0],
        expiration_date_flag: false,
        isExpirationFixed: undefined,
        expirationDate: undefined,
        isExpirationHard: undefined,
        daysToComplete: undefined,
      };

      return newObject;
    } else {
      let check = {
        ...dataTable.enrollmentResponses[0],
        isExpirationFixed: !isTrueValue(
          dataTable.enrollmentResponses[0].isExpirationFixed,
        ),
        expiration_date_flag: true,
      };
      return check;
    }
  };

  return (
    <AnimationFadeIn>
      <BackgroundImageCst
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-certificates.jpg" //PAGINA DI SFONDO
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        {columns && (
          <Box
            sx={{
              mt: "25px",
            }}
          >
            <AddEditFormCst
              row={getExpirationValue()}
              columns={columns}
              inputsException={inputsException}
              onSubmit={onSubmit}
              actionsForForms={[]}
              backButton={() => {
                navigate(-1);
              }}
            />
          </Box>
        )}
      </Container>
      <ModalConfirmsCst
        open={!!openToast}
        title={""}
        description={""}
        onCallBackClosed={() => {
          setOpenToast(undefined);
        }}
        status={openToast}
      />
    </AnimationFadeIn>
  );
};

export default Enrollments;
