//REACT AND REACT BASED LIBRARIES
import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

//COMPONENTS
import { Box, Container } from "@mui/material";
import AnimationFadeIn from "@components/AnimationFadeIn";
import Breadcrumb from "@components/Breadcrumb";
import HeroCst from "@components/HeroCst";
import BackgroundImage from "@components/BackgroundImageCst";
//CUSTOM HOOKS AND UTILS
import { ServicesURL } from "@utils/utilsApi";
import { PagesID } from "@utils/utilsConfigurations";
import { useFetchTable } from "@hooks/useFetchDataTable";
import ButtonBackPage from "@components/ButtonBackPage";
import MyClassListBoxCst from "@components/MyClassListBoxCst";
import dayjs from "dayjs";

//LEGENDA
// !! IMPORTANTE/ CORE PAGINA
// ?? FACOLTATIVO/ DIPENDE DALLA NECESSITÀ
// ## USATO PER DATI MOCKATI, NON NECESSARIO UNA VOLTA RICEVUTI I SERVIZI BE

const MyClasses: React.FC = () => {
  //!! HOOK PER GESTIRE TRADUZIONI
  const { t } = useTranslation(["teachers-area-my-classes-page"]); //NECESSARIO AGGIORNARE FILES DI TRADUZIONE

  const { id } = useParams();

  //!! CHIAMATA GET
  const { columns, dataTable, handleFetchData, actionForRow } = useFetchTable(
    `${ServicesURL.getEditions}?corporateId=${id || "1"}`,
    PagesID["corporate.teachersArea.my-classes"],
  );

  const listClassroomsEditions = useMemo(() => {
    let newData = { ...dataTable };
    let newOutput: [];
    if (newData?.output) {
      newOutput = (newData?.output || []).map((edition: any) => {
        return {
          edition_id: edition?.id,
          edition_code: edition?.edition_code,
          id_lo: edition?.learning_objects?.id,
          label_lo: edition?.learning_objects?.label,
          faculty: edition?.faculties.map((faculty: any) => {
            return `${faculty?.label}`;
          }),
        };
      });
      return { ...newData, output: newOutput };
    }
  }, [dataTable]);

  const filterColumns = [
    {
      accessorKey: "teacher_id",
      type: "autoIncrement",
      required: true,
      hasFilter: true,
      visibility: true,
      header: "idTeacher",
    },
    {
      accessorKey: "teacher_name",
      type: "text",
      hasFilter: true,
      header: "nameTeacher",
    },
    {
      accessorKey: "teacher_surname",
      type: "text",
      hasFilter: true,
      header: "teacherSurname",
    },
    {
      accessorKey: "classroom_id",
      type: "autoIncrement",
      hasFilter: true,
      header: "classroomId",
    },
    {
      accessorKey: "classroom",
      type: "text",
      hasFilter: true,
      header: "classroom",
    },
    {
      accessorKey: "edition_id",
      type: "autoIncrement",
      hasFilter: true,
      header: "editionId",
    },
    {
      accessorKey: "edition_code",
      type: "text",
      hasFilter: true,
      header: "editionCode",
    },
    {
      accessorKey: "date",
      type: "dateTime",
      hasFilter: false,
      header: "editionStartDate",
    },
    {
      accessorKey: "exercise_title",
      type: "text",
      hasFilter: false,
      header: "exerciseTitle",
    },
    {
      accessorKey: "exercise_file",
      type: "text",
      hasFilter: false,
      header: "exerciseFile",
    },
  ];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-my-classes.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`${t("hero-title")}`}
          description={t("hero-description")}
        />
        <ButtonBackPage />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <MyClassListBoxCst
            filterList={filterColumns}
            actionPermissions={actionForRow}
            fileList={listClassroomsEditions}
            paginationDefault={20}
            onChangeFilterForm={(filters: { [x: string]: any }) => {
              const parseFilters = { ...filters };
              if (filters.date) {
                parseFilters.date = dayjs(parseFilters.date).format(
                  "YYYY-MM-DD",
                );
              }
              handleFetchData(parseFilters);
            }}
          />
        </Box>
      </Container>
    </AnimationFadeIn>
  );
};

export default MyClasses;
