import React, { useCallback, useMemo, useState } from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
// import { InputBaseComponentInterface } from "./types";
import { useTranslation } from "react-i18next";
import InputCst from "../InputCst";
import ButtonCst from "@components/ButtonCst";
import { Add, Delete, RemoveRedEye } from "@mui/icons-material";
import { MultiTextInterface } from "./types";
import SelectCst from "../SelectCst";
import ModalCst from "@components/ModalCst";
import InputChipsCst from "../InpuctChipsCst";
import MultiSelectCst from "../MultiSelectCst";
import InputAssociationChipsCst from "../InputAssociationChipsCst";
// import { ServicesURL } from "@utils/utilsApi";
// import { PagesID } from "@utils/utilsConfigurations";
// import AssociationTableCst from "@components/AssociationTableCst";
import { defaultValidation } from "@utils/utilsValidators";

const disciplinesList = [
  { label: "Allergologia ed immunologia clinica - Medico chirurgo", value: 1 },
  { label: "Angiologia - Medico chirurgo", value: 2 },
  { label: "Cardiologia - Medico chirurgo", value: 3 },
  { label: "Dermatologia e venereologia - Medico chirurgo", value: 4 },
  { label: "Ematologia - Medico chirurgo", value: 5 },
  { label: "Endocrinologia - Medico chirurgo", value: 6 },
  { label: "Gastroenterologia - Medico chirurgo", value: 7 },
  { label: "Genetica medica - Medico chirurgo", value: 8 },
  { label: "Geriatria - Medico chirurgo", value: 9 },
  { label: "Malattie metaboliche e diabetologia - Medico chirurgo", value: 10 },
  { label: "Malattie dell'apparato respiratorio - Medico chirurgo", value: 11 },
  { label: "Malattie infettive - Medico chirurgo", value: 12 },
  {
    label:
      "Medicina e chirurgia di accettazione e di urgenza - Medico chirurgo",
    value: 13,
  },
  { label: "Medicina fisica e riabilitazione - Medico chirurgo", value: 14 },
  { label: "Medicina interna - Medico chirurgo", value: 15 },
  { label: "Medicina termale - Medico chirurgo", value: 16 },
  { label: "Medicina aeronautica e spaziale - Medico chirurgo", value: 17 },
  { label: "Medicina dello sport - Medico chirurgo", value: 18 },
  { label: "Nefrologia - Medico chirurgo", value: 19 },
  { label: "Neonatologia - Medico chirurgo", value: 20 },
  { label: "Neurologia - Medico chirurgo", value: 21 },
  { label: "Neuropsichiatria infantile - Medico chirurgo", value: 22 },
  { label: "Oncologia - Medico chirurgo", value: 23 },
  { label: "Pediatria - Medico chirurgo", value: 24 },
  { label: "Psichiatria - Medico chirurgo", value: 25 },
  { label: "Radioterapia - Medico chirurgo", value: 26 },
  { label: "Reumatologia - Medico chirurgo", value: 27 },
  { label: "Cardiochirurgia - Medico chirurgo", value: 29 },
  { label: "Chirurgia generale - Medico chirurgo", value: 30 },
  { label: "Chirurgia maxillo-facciale - Medico chirurgo", value: 31 },
  { label: "Chirurgia pediatrica - Medico chirurgo", value: 32 },
  { label: "Chirurgia plastica e ricostruttiva - Medico chirurgo", value: 33 },
  { label: "Chirurgia toracica - Medico chirurgo", value: 34 },
  { label: "Chirurgia vascolare - Medico chirurgo", value: 35 },
  { label: "Ginecologia e ostetricia - Medico chirurgo", value: 36 },
  { label: "Neurochirurgia - Medico chirurgo", value: 37 },
  { label: "Oftalmologia - Medico chirurgo", value: 38 },
  { label: "Ortopedia e traumatologia - Medico chirurgo", value: 39 },
  { label: "Otorinolaringoiatria - Medico chirurgo", value: 40 },
  { label: "Urologia - Medico chirurgo", value: 41 },
  { label: "Anatomia patologica - Medico chirurgo", value: 42 },
  { label: "Anestesia e rianimazione - Medico chirurgo", value: 43 },
  { label: "Biochimica clinica - Medico chirurgo", value: 44 },
  { label: "Farmacologia e tossicologia clinica - Medico chirurgo", value: 45 },
  { label: "Laboratorio di genetica medica - Medico chirurgo", value: 46 },
  { label: "Medicina trasfusionale - Medico chirurgo", value: 47 },
  { label: "Medicina legale - Medico chirurgo", value: 48 },
  { label: "Medicina nucleare - Medico chirurgo", value: 49 },
  { label: "Microbiologia e virologia - Medico chirurgo", value: 50 },
  { label: "Neurofisiopatologia - Medico chirurgo", value: 51 },
  { label: "Neuroradiologia - Medico chirurgo", value: 52 },
  {
    label:
      "Patologia clinica (laboratorio di analisi chimico-cliniche e microbiologia) - Medico chirurgo",
    value: 53,
  },
  { label: "Radiodiagnostica - Medico chirurgo", value: 54 },
  {
    label: "Igiene, epidemiologia e sanità pubblica - Medico chirurgo",
    value: 55,
  },
  {
    label: "Igiene degli alimenti e della nutrizione - Medico chirurgo",
    value: 56,
  },
  {
    label:
      "Medicina del lavoro e sicurezza degli ambienti di lavoro - Medico chirurgo",
    value: 57,
  },
  {
    label: "Medicina generale (medici di famiglia) - Medico chirurgo",
    value: 58,
  },
  { label: "Continuità assistenziale - Medico chirurgo", value: 59 },
  {
    label: "Pediatria (pediatri di libera scelta) - Medico chirurgo",
    value: 60,
  },
  {
    label:
      "Igiene degli allevamenti e delle produzioni zootecniche - Veterinario",
    value: 61,
  },
  {
    label:
      "Igiene prod., trasf., commercial., conserv. E tras. Alimenti di origine animale e derivati - Veterinario",
    value: 62,
  },
  { label: "Sanità animale - Veterinario", value: 63 },
  { label: "Odontoiatria - Odontoiatra", value: 64 },
  { label: "Farmacista pubblico del SSN - Farmacista", value: 66 },
  { label: "Farmacista territoriale - Farmacista", value: 67 },
  { label: "Biologo - Biologo", value: 68 },
  { label: "Farmacista di altro settore - Farmacista", value: 118 },
  { label: "Chimica analitica - Chimico", value: 76 },
  { label: "Psicoterapia - Psicologo", value: 77 },
  { label: "Psicologia - Psicologo", value: 78 },
  { label: "Fisica sanitaria - Fisico", value: 79 },
  { label: "Assistente sanitario - Assistente sanitario", value: 80 },
  { label: "Dietista - Dietista", value: 81 },
  { label: "Fisioterapista - Fisioterapista", value: 82 },
  { label: "Educatore professionale - Educatore professionale", value: 83 },
  { label: "Igienista dentale - Igienista dentale", value: 84 },
  { label: "Infermiere - Infermiere", value: 85 },
  { label: "Infermiere pediatrico - Infermiere pediatrico", value: 86 },
  { label: "Logopedista - Logopedista", value: 87 },
  {
    label:
      "Ortottista/assistente di oftalmologia - Ortottista/assistente di oftalmologia",
    value: 88,
  },
  { label: "Ostetrica/o - Ostetrica/o", value: 89 },
  { label: "Podologo - Podologo", value: 90 },
  {
    label:
      "Tecnico della riabilitazione psichiatrica - Tecnico della riabilitazione psichiatrica",
    value: 91,
  },
  {
    label:
      "Tecnico della fisiopatologia cardiocircolatoria e perfusione cardiovascolare - Tecnico della fisiopatologia cardiocircolatoria e perfusione cardiovascolare",
    value: 92,
  },
  {
    label:
      "Tecnico sanitario laboratorio biomedico - Tecnico sanitario laboratorio biomedico",
    value: 93,
  },
  {
    label:
      "Tecnico sanitario di radiologia medica - Tecnico sanitario di radiologia medica",
    value: 94,
  },
  { label: "Tecnico audiometrista - Tecnico audiometrista", value: 95 },
  { label: "Tecnico audioprotesista - Tecnico audioprotesista", value: 96 },
  {
    label: "Tecnico di neurofisiopatologia - Tecnico di neurofisiopatologia",
    value: 98,
  },
  { label: "Tecnico ortopedico - Tecnico ortopedico", value: 99 },
  {
    label:
      "Terapista della neuro e psicomotricità dell'età evolutiva - Terapista della neuro e psicomotricità dell'età evolutiva",
    value: 100,
  },
  { label: "Terapista occupazionale - Terapista occupazionale", value: 101 },
  {
    label:
      "Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro - Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro",
    value: 105,
  },
  {
    label: "Scienza dell'alimentazione e dietetica - Medico chirurgo",
    value: 106,
  },
  {
    label: "Direzione medica di presidio ospedaliero - Medico chirurgo",
    value: 107,
  },
  {
    label: "Organizzazione dei servizi sanitari di base - Medico chirurgo",
    value: 108,
  },
  { label: "Audiologia e foniatria - Medico chirurgo", value: 111 },
  { label: "Psicoterapia - Medico chirurgo", value: 112 },
  { label: "Privo di specializzazione * - Medico chirurgo", value: 113 },
  { label: "Cure palliative - Medico chirurgo", value: 114 },
  { label: "Epidemiologia - Medico chirurgo", value: 115 },
  { label: "Medicina di comunità - Medico chirurgo", value: 116 },
  { label: "Medicina subacquea e iperbarica - Medico chirurgo", value: 117 },
  {
    label: "Iscritto nell'elenco speciale ad esaurimento - Dietista",
    value: 119,
  },
  {
    label:
      "Iscritto nell'elenco speciale ad esaurimento - Educatore professionale",
    value: 120,
  },
  {
    label: "Iscritto nell'elenco speciale ad esaurimento - Fisioterapista",
    value: 121,
  },
  {
    label: "Iscritto nell'elenco speciale ad esaurimento - Igienista dentale",
    value: 122,
  },
  {
    label: "Iscritto nell'elenco speciale ad esaurimento - Logopedista",
    value: 123,
  },
  {
    label:
      "Iscritto nell'elenco speciale ad esaurimento - Ortottista/assistente di oftalmologia",
    value: 124,
  },
  {
    label: "Iscritto nell'elenco speciale ad esaurimento - Podologo",
    value: 125,
  },
  {
    label:
      "Iscritto nell'elenco speciale ad esaurimento - Tecnico audiometrista",
    value: 126,
  },
  {
    label:
      "Iscritto nell'elenco speciale ad esaurimento - Tecnico audioprotesista",
    value: 127,
  },
  {
    label:
      "Iscritto nell'elenco speciale ad esaurimento - Tecnico della fisiopatologia cardiocircolatoria e perfusione cardiovascolare",
    value: 128,
  },
  {
    label:
      "Iscritto nell'elenco speciale ad esaurimento - Tecnico della prevenzione nell'ambiente e nei luoghi di lavoro",
    value: 129,
  },
  {
    label:
      "Iscritto nell'elenco speciale ad esaurimento - Tecnico della riabilitazione psichiatrica",
    value: 130,
  },
  {
    label:
      "Iscritto nell'elenco speciale ad esaurimento - Tecnico di neurofisiopatologia",
    value: 131,
  },
  {
    label: "Iscritto nell'elenco speciale ad esaurimento - Tecnico ortopedico",
    value: 132,
  },
  {
    label:
      "Iscritto nell'elenco speciale ad esaurimento - Tecnico sanitario laboratorio biomedico",
    value: 133,
  },
  {
    label:
      "Iscritto nell'elenco speciale ad esaurimento - Terapista della neuro e psicomotricità dell'età evolutiva",
    value: 134,
  },
];

const MultiTextCst: React.FC<MultiTextInterface> = (props) => {
  const theme = useTheme();
  const { t } = useTranslation(["input"]);
  const { t: tForm } = useTranslation(["form"]);
  const { t: tAdd } = useTranslation(["breadcrumb"]);
  const {
    id,
    fieldsHasType,
    fieldsToShow,
    label,
    value,
    fields,
    onChange,
    disabled,
    callBack,
    onAssociationOpen,
    associationValue,
    removeAssociation,
    initialAccessorKey,
    selectedAccesorKey,
  } = props;
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [detailList, setDetailList] = useState<any[]>([]);
  const [errorForm, setErrorForm] = useState<any>({});

  const inputObject = fields.reduce(
    (acc, cur: any) => ({ ...acc, [cur.accessorKey]: "" }),
    {},
  );

  const [object, setObject] = useState<any>(inputObject);

  const fieldsMemo: any = useMemo(() => {
    if (callBack) {
      return callBack(object) || [];
    } else {
      return fields || [];
    }
  }, [object]);

  const handleAddObject = () => {
    let array = value || [];

    let newObject = { ...object };

    if (associationValue && associationValue.length > 0) {
      newObject = { ...newObject, user_id: associationValue[0] };
    }

    const { canBeSubmit, showError } = defaultValidation(fields, newObject);

    setErrorForm(showError);

    if (!!object.url && object.url === "") return;

    if (!canBeSubmit) return;

    array.push(newObject);
    onChange(array);
    removeAssociation && removeAssociation([]);
    setObject(inputObject);
  };

  const handleDelete = (obj: any) => {
    const newList = value.filter((item: any) => item !== obj);
    onChange(newList);
  };

  const handleDetailLine = (object: any) => {
    let detailObjectLine: any = [];

    fieldsMemo.forEach((field: any) => {
      let cmObj = {
        label: field.label,
        value: "",
      };

      if (Array.isArray(object[field.accessorKey])) {
        if (field.accessorKey === "disciplines") {
          const stringArray = object[field.accessorKey].map(function (
            item: any,
          ) {
            return item["label"];
          });
          cmObj.value = stringArray.toString();
        } else {
          cmObj.value = object[field.accessorKey].toString();
        }
      } else {
        cmObj.value = object[field.accessorKey];
      }

      detailObjectLine.push(cmObj);
    });

    setDetailList(detailObjectLine);
    setOpenModal(!openModal);
  };

  const getInputDisabled = (key: any) => {
    if (key === "elapsed" && !!object["sessions"]) {
      return true;
    }

    if (key === "elapsed" && !!object["sessions"]) {
      return true;
    }

    if (key === "sessions" && !!object["elapsed"]) {
      return true;
    }
  };

  const getValues = useCallback(
    (accessorKey: string) => {
      if (selectedAccesorKey !== initialAccessorKey) return;
      switch (accessorKey) {
        case "user_id":
          return associationValue;
      }
    },
    [associationValue, initialAccessorKey, selectedAccesorKey],
  );

  const setOpenAssociation = (accessorKey: string) => {
    onAssociationOpen && onAssociationOpen(accessorKey);
  };

  const handleDeleteAssociation = (accessorKey: string, e: { id: string }) => {
    switch (accessorKey) {
      case "user_id":
        removeAssociation(
          associationValue.filter((element: any) => element.id !== e.id),
        );
        break;
    }
  };

  const getErrMsg = (accessorKey: string): string => {
    return errorForm[accessorKey]?.msg || errorForm[accessorKey];
  };

  const minLength = (accessorKey: string): number => {
    return errorForm[accessorKey]?.value;
  };

  const maxLength = (accessorKey: string): number => {
    return errorForm[accessorKey]?.value;
  };

  return (
    <>
      <Grid item xs={12}>
        <Box
          sx={{
            border: `1px solid ${theme.palette.text.tertiary}`,
            padding: "32px 16px",
            display: "flex",
            flexDirection: "column",
            borderRadius: "4px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              padding: "5px",
              position: "absolute",
              top: 0,
              left: "10px",
              backgroundColor: theme?.palette.background.paper,
              transform: "translate(0, -50%)",
              color: disabled
                ? theme.palette.text.tertiary
                : theme.palette.text.primary,
            }}
          >
            {label}
          </Box>
          <Grid container spacing={"24px"}>
            {fieldsMemo.map((field: any) => {
              if (fieldsHasType) {
                switch (field.type) {
                  case "text": {
                    return (
                      <Grid item key={field.accessorKey} xs={12} md={6} xl={4}>
                        <InputCst
                          id={field.accessorKey}
                          label={field.label}
                          value={object[field.accessorKey]}
                          required={false}
                          disabled={getInputDisabled(field.accessorKey)}
                          error={!!errorForm[field.accessorKey]}
                          errorMessage={
                            tForm(
                              `error.field.${getErrMsg(field.accessorKey)}`,
                              {
                                min: minLength(field.accessorKey),
                                max: maxLength(field.accessorKey),
                              },
                            ) || "Error"
                          }
                          onChange={(e: any) => {
                            setObject({
                              ...object,
                              ...{ [field.accessorKey]: e.target.value },
                            });
                          }}
                          type={field.inpuType || "text"}
                        />
                      </Grid>
                    );
                  }
                  case "select": {
                    return (
                      <Grid key={field.accessorKey} item xs={12} md={6} xl={4}>
                        <SelectCst
                          id={field.accessorKey}
                          optionsKey={field.optionsKey}
                          label={field.label}
                          value={object[field.accessorKey]?.toString()}
                          // service={col.service}
                          required={field.required}
                          onChange={(e: any) => {
                            setObject({
                              ...object,
                              ...{ [field.accessorKey]: e },
                            });
                          }}
                          options={field?.options}
                          disabled={false}
                        />
                      </Grid>
                    );
                  }
                  case "chips": {
                    return (
                      <Grid key={field.accessorKey} item xs={12} md={6} xl={4}>
                        <InputChipsCst
                          label={field.label}
                          id={field.accessorKey}
                          value={object[field.accessorKey] || []}
                          required={field.required}
                          disabled={field.disabled}
                          onAdd={(list: any[]) => {
                            setObject({
                              ...object,
                              ...{ [field.accessorKey]: list },
                            });
                          }}
                        />
                      </Grid>
                    );
                  }
                  case "select-checkbox": {
                    return (
                      <Grid key={field.accessorKey} item xs={12} md={6} xl={4}>
                        <MultiSelectCst
                          id={field.accessorKey}
                          label={field.label}
                          optionsKey={field.optionsKey}
                          value={object[field.accessorKey]}
                          onChange={(e: any) => {
                            setObject({
                              ...object,
                              ...{ [field.accessorKey]: e },
                            });
                          }}
                          options={disciplinesList}
                        />
                      </Grid>
                    );
                  }
                  case "association": {
                    return (
                      <Grid key={field.accessorKey} item xs={12} md={6} xl={4}>
                        <InputAssociationChipsCst
                          key={field.accessorKey}
                          id={field.accessorKey}
                          label={field.label}
                          value={getValues(field.accessorKey)}
                          required={field.required}
                          textTooltip={field.tooltip}
                          onDelete={(e: any) =>
                            handleDeleteAssociation(field.accessorKey, e)
                          }
                          error={!!errorForm[field.accessorKey]}
                          errorMessage={
                            tForm(
                              `error.field.${getErrMsg(field.accessorKey)}`,
                              {
                                min: minLength(field.accessorKey),
                                max: maxLength(field.accessorKey),
                              },
                            ) || "Error"
                          }
                          onRedirect={() =>
                            setOpenAssociation(field.accessorKey)
                          }
                          labelKey={"label"}
                          disabled={field.disabled}
                        />
                      </Grid>
                    );
                  }
                }
              } else {
                return (
                  <Grid item key={field.accessorKey} xs={12} md={6}>
                    <InputCst
                      id={field.accessorKey}
                      label={tForm(field.label) || field.label}
                      value={object[field.accessorKey]}
                      required={false}
                      disabled={
                        (disabled ? disabled : field?.disabled) || false
                      }
                      onChange={(e: any) => {
                        setObject({
                          ...object,
                          ...{ [field.accessorKey]: e.target.value },
                        });
                      }}
                      type={field.inpuType || "text"}
                    />
                  </Grid>
                );
              }
            })}
            <Grid
              item
              xs={12}
              md={6}
              sx={{ textAlign: "right", ml: "auto", alignSelf: "flex-end" }}
            >
              <ButtonCst
                id={"add-btn"}
                onClick={() => handleAddObject()}
                variant="contained"
                title={`Add ${label}`}
                disabled={disabled}
                sx={{
                  textAlign: "right",
                  [theme.breakpoints.down("sm")]: { width: "100%" },
                }}
              >
                {`${tAdd("add")}`}
              </ButtonCst>
            </Grid>
          </Grid>
          {value && value.length > 0 && (
            <Box
              sx={{
                pt: 2,
                mt: 4,
                borderTop: `1px solid ${theme.palette.text.primary}`,
                [theme.breakpoints.up("sm")]: { pt: 3 },
              }}
            >
              {value.map((item: any) => {
                const keys = Object.keys(item);
                const objectToShow = keys
                  .filter((key) => fieldsToShow?.includes(key))
                  .reduce((cur, key) => {
                    return Object.assign(cur, { [key]: item[key] });
                  }, {});

                let labels;
                if (Object.values(objectToShow).length > 0) {
                  labels = Object.values(objectToShow);
                } else {
                  labels = Object.values(item);
                }
                return (
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(12,1fr)",
                      gridGap: "16px",
                      py: 2,
                      [theme.breakpoints.up("sm")]: {
                        gridTemplateColumns: "0.5fr 2fr 2fr 2fr 0.5fr",
                        gridGap: "24px",
                        py: 1,
                      },
                      "&:not(:first-child)": {
                        borderTop: "1px solid rgba(255, 255, 255, 0.5)",
                      },
                    }}
                  >
                    <ButtonCst
                      id={"delete-uog"}
                      onClick={() => handleDelete(item)}
                      title="Delete uog"
                      sx={{
                        p: 0,
                        minWidth: "auto",
                        height: "24px",
                        [theme.breakpoints.down("sm")]: {
                          gridColumn: fieldsToShow ? "11/span 1" : "12/span 1",
                          order: 3,
                          alignSelf: "flex-end",
                        },
                      }}
                    >
                      <Delete />
                    </ButtonCst>
                    {labels.map((label: any) => (
                      <Typography
                        sx={{
                          wordBreak: "break-word",
                          [theme.breakpoints.down("sm")]: {
                            gridColumn: "auto/span 12",
                          },
                        }}
                      >
                        {!!label?.id ? label.id : label}
                        {!!label?.label ? ` - ${label.label}` : ""}
                      </Typography>
                    ))}
                    {fieldsToShow && fieldsToShow.length > fields.length && (
                      <ButtonCst
                        id={"view-detail"}
                        onClick={() => handleDetailLine(item)}
                        title="View detail"
                        sx={{
                          p: 0,
                          minWidth: "auto",
                          height: "24px",
                          gridColumn: "5/span 1",
                          [theme.breakpoints.down("sm")]: {
                            gridColumn: "12/span 1",
                            order: 3,
                            alignSelf: "flex-end",
                          },
                        }}
                      >
                        <RemoveRedEye
                          sx={{ fill: theme.palette.text.primary }}
                        />
                      </ButtonCst>
                    )}
                  </Box>
                );
              })}
            </Box>
          )}
        </Box>
      </Grid>
      <ModalCst
        open={openModal}
        setOpen={setOpenModal}
        title={"Dettaglio"}
        typeOfModal={"multiTextDetail"}
        data={detailList}
      />
    </>
  );
};

export default MultiTextCst;
