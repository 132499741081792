import React, { useEffect, useState } from "react";
import { Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";
import ButtonBackPage from "../../../../../../components/ButtonBackPage";
import CustomRowAction from "../../../../../../components/Table/components/CustomRowAction";
import ModalCst from "../../../../../../components/ModalCst";
import { useFetchTable } from "../../../../../../hooks/useFetchDataTable";
import { ServicesURL } from "../../../../../../utils/utilsApi";
import { useParams } from "react-router-dom";
import useSWRMutation from "swr/mutation";
import { axiosInstance } from "../../../../../../services/config";
import { TableTabs } from "../../../../../../components/TableTabs/Index";

const NextStep: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [errorApi, setErrorApi] = useState<boolean>(false);
  const { t } = useTranslation(["training-needs-details-step"]);
  const { id, idCampaign } = useParams();

  const [rowData, setRowData] = useState<any>();

  const dataManager = useFetchTable(
    `${ServicesURL.digitedCampaignLevels}`
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{levelType}", `MANAGER`),
    PagesID["corporate.campaign.gap.management.nextStep.manager"]
  );
  const dataHR = useFetchTable(
    `${ServicesURL.digitedCampaignLevels}`
      .replace("{campaignId}", `${idCampaign}`)
      .replace("{levelType}", `HR`),
    PagesID["corporate.campaign.gap.management.nextStep.hr"]
  );

  const dataTabSelected =
    selectedTab == 0
      ? dataManager &&
        dataManager?.dataTable?.output &&
        dataManager?.dataTable?.output.length
        ? dataManager
        : dataHR
      : dataHR;

  const { trigger: editStep } = useSWRMutation(
    ServicesURL.digitedCampaign + `/${idCampaign}/levels/${rowData?.id}`,
    (url: string, arg: { arg: any }) => {
      return () => axiosInstance.put(`${url}`, arg.arg);
    }
  );

  const handleCompleteStep = async () => {
    const payloadPUT: any = {
      level_state: "COMPLETED",
    };

    try {
      await editStep(payloadPUT);
      dataTabSelected.mutate &&
        dataTabSelected.dataTable &&
        dataTabSelected.mutate({
          ...dataTabSelected.dataTable?.output,
        });
    } catch (error) {
      setErrorApi(true);
      console.error(error);
    }
  };

  const handleNextStep = async () => {
    const payloadPUT: any = {
      level_state: "IN_PROGRESS",
    };

    try {
      await editStep(payloadPUT);
      dataTabSelected.mutate({
        ...dataTabSelected.dataTable,
      });
    } catch (error) {
      setErrorApi(true);
      console.error(error);
    }
  };

  const initialStatus = rowData?.level_state == "BLOCKED";

  const finalStatus = rowData?.level_state == "IN_PROGRESS";

  const configurationActionForRow = React.useMemo(() => {
    return (row: any) => {
      let disabled = false;
      let switchIcon = false;
      return dataTabSelected.actionForRow?.map((action: any) => {
        switch (action.type) {
          case "nextStep": {
            switchIcon = row.original?.level_state == "COMPLETED";
            const disabledNextStepLevel =
              dataManager?.dataTable?.output?.[0]?.id == row.original.id
                ? row.original.level_state == "COMPLETED"
                : dataHR?.dataTable?.output?.[0]?.id != row.original.id
                ? row.original.level_state == "BLOCKED"
                : false;

            disabled = disabledNextStepLevel;
            return {
              ...action,
              disabled: disabledNextStepLevel,
              switchIcon: switchIcon,
            };
          }
          default:
            return action;
        }
      });
    };
  }, [dataTabSelected.actionForRow]);

  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew = configurationActionForRow(row) && [
      ...configurationActionForRow(row),
    ];
    const newRow = {
      ...row,
      original: {
        ...row.original,
      },
    };

    return [
      <CustomRowAction
        key={row.id}
        row={newRow}
        configurationActionForRow={configRowNew}
        setActionData={setRowData}
        setOpenModal={setOpenModal}
      />,
    ];
  };

  const tabs = [
    dataManager &&
      dataManager?.dataTable?.output &&
      dataManager?.dataTable?.output?.length > 0 && {
        manager: (
          <Table
            id={
              PagesID["corporate.campaign.gap.management.nextStep.manager"]
            }
            data={dataManager.dataTable.output}
            columns={dataTabSelected.columns || []}
            hiddenFilters
            onChangeFilterForm={dataTabSelected.handleFetchData}
            renderRowActions={customRenderRowActions}
            configurationTopToolbar={dataTabSelected.actionsTopToolbar}
            totalPages={
              dataTabSelected.dataTable?.metadata?.total_elements || 1
            }
            modalTitle={t("hero-title")?.replace("-", "") || ""}
            setActionData={setRowData}
          />
        ),
      },
    dataHR &&
      dataHR?.dataTable?.output &&
      dataHR?.dataTable?.output.length > 0 && {
        hr: (
          <Table
            id={PagesID["corporate.campaign.gap.management.nextStep.hr"]}
            data={dataHR.dataTable.output || []}
            columns={dataTabSelected.columns || []}
            hiddenFilters
            onChangeFilterForm={dataTabSelected.handleFetchData}
            renderRowActions={customRenderRowActions}
            configurationTopToolbar={dataTabSelected.actionsTopToolbar}
            totalPages={
              dataTabSelected.dataTable?.metadata?.total_elements || 1
            }
            modalTitle={t("hero-title")?.replace("-", "") || ""}
            setActionData={setRowData}
          />
        ),
      },
  ].filter(Boolean);

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst title={t("hero-title")} description={t("hero-description")} />
        <ButtonBackPage goBack={2} />
        <TableTabs setExternalSelectedTab={setSelectedTab} tabs={tabs} />
      </Container>
      <ModalCst
        open={openModal}
        setOpen={setOpenModal}
        title={
          initialStatus ? t("activate-step") || "" : t("pass-next-step") || ""
        }
        description={
          initialStatus
            ? t("activate-step-description") || ""
            : t("pass-next-level") || ""
        }
        typeOfModal={"confirm"}
        data={{
          callBackSubmit: () => {
            finalStatus ? handleCompleteStep() : handleNextStep();
            setOpenModal(false);
          },
        }}
        messageToast={
          errorApi
            ? t("error-api") || ""
            : initialStatus
            ? t("activate-step-success") || ""
            : t("pass-next-level-succes") || ""
        }
        typeToast={errorApi ? "error" : "success"}
      />
    </AnimationFadeIn>
  );
};

export default NextStep;
