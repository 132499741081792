import React from "react";
import { Box, Container } from "@mui/material";
import { useTranslation } from "react-i18next";
import BackgroundImage from "@components/BackgroundImageCst";
import Table from "@components/Table";
import HeroCst from "@components/HeroCst";
import Breadcrumb from "@components/Breadcrumb";
import { PagesID } from "@utils/utilsConfigurations";
import AnimationFadeIn from "@components/AnimationFadeIn";
import { useResetFilters } from "@hooks/useFiltersProvider";
import ButtonBackPage from "../../../../../../../components/ButtonBackPage";
import { TableTabs } from "../../../../../../../components/TableTabs/Index";
import { useFetchTable } from "../../../../../../../hooks/useFetchDataTable";
import { ServicesURL } from "../../../../../../../utils/utilsApi";
import { useParams } from "react-router-dom";
import CustomRowAction from "../../../../../../../components/Table/components/CustomRowAction";

const CollectionResults: React.FC = () => {
  const { t } = useTranslation(["training-needs-details-user"]);
  const { id, idCampaign, idDetail } = useParams();

  useResetFilters();

  const detailUsers = useFetchTable(
    `${ServicesURL.digitedUsersCampaign}`.replace(
      "{campaignId}",
      `${idDetail}`,
    ) + `?status=COMPLETED&role=LEARNER`,
    PagesID["corporate.learner.campaign.collection.results"],
  );

  const configurationActionForRow = React.useMemo(() => {
    return (row: any) => {
      return detailUsers.actionForRow
        ?.filter((action: any) => {
          if (action.type == "visibility") {
            return row.original.like_dislike_status === true;
          }
          if (action.type == "collectionResults") {
            return row.original.like_dislike_status != true;
          }
          return true;
        })
        .map((action: any) => {
          switch (action.type) {
            case "collectionResults": {
              return {
                ...action,
                urlId: row.original.id,
              };
            }
            case "visibility": {
              return {
                ...action,
                useId: row.original.id,
              };
            }
            default:
              return action;
          }
        });
    };
  }, [detailUsers.actionForRow]);
  const customRenderRowActions = ({ row }: { row: any }): React.ReactNode => {
    let configRowNew = configurationActionForRow(row) && [
      ...configurationActionForRow(row),
    ];

    return [
      <CustomRowAction
        key={row.id}
        row={row}
        configurationActionForRow={configRowNew}
      />,
    ];
  };

  const tabs = [
    {
      "Per Learner": (
        <Table
          id={PagesID["corporate.campaign.edit.learner"]}
          data={detailUsers?.dataTable?.output || []}
          columns={detailUsers.columns || []}
          renderRowActions={customRenderRowActions}
          onChangeFilterForm={detailUsers.handleFetchData}
          configurationTopToolbar={detailUsers.actionsTopToolbar}
          configurationActionForRow={detailUsers.actionForRow}
          totalPages={detailUsers.dataTable?.metadata?.total_elements}
          modalTitle={t("hero-title")?.replace("-", "") || ""}
          enableRowActions={true}
        />
      ),
    },
  ];

  return (
    <AnimationFadeIn>
      <BackgroundImage
        customWidth={"100%"}
        customHeight={"603px"}
        image="section-training-needs.jpg"
        position={"absolute"}
        fullpage={true}
      />
      <Breadcrumb />
      <Container
        maxWidth={"xxl"}
        sx={{
          position: "relative",
          zIndex: 1,
        }}
      >
        <HeroCst
          title={`Raccolta Risultati`}
          description={t("hero-description")}
        />
        <ButtonBackPage goBack={2} />
        <Box
          sx={{
            mt: "25px",
          }}
        >
          <TableTabs tabs={tabs} />
        </Box>
      </Container>
    </AnimationFadeIn>
  );
};

export default CollectionResults;
